import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

// COMPONENT IMPORTS
import CapsyncIcon from '../CapsyncIcon';
import { VALIDATION_MESSAGE } from '../../constants/message';

/* ============================== FILE UPLOAD ============================== */
const FileUpload = ({ onHandleFileUpload, errorMessage, setErrorMessage, multiple = true }) => {
  const onDropAccepted = (files) => {
    if (!multiple && files.length !== 1) {
      setErrorMessage && setErrorMessage(VALIDATION_MESSAGE.FILE_ONLY_SINGLE_IMAGE_ALLOWED);
      return false;
    }
    onHandleFileUpload(files);
  };

  const onDropRejected = () => {
    setErrorMessage && setErrorMessage(VALIDATION_MESSAGE.FILE_ONLY_IMAGE_ALLOWED);
    return false;
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png']
    },
    onDropAccepted,
    onDropRejected
  });

  return (
    <div className="cs-upload-box">
      <label className="cs-regular-sub-heading-s cs-neutral-100">Upload files</label>
      <div {...getRootProps()} className="cs-upload-container">
        <input {...getInputProps()} name="profileImage" />
        <span className="cs-primary upload-icon">
          <CapsyncIcon title="upload-files-filled" size="28" />
        </span>
        <p className="cs-regular-sub-heading-m cs-neutral-90">
          <span className="cs-primary">Browse</span>&nbsp;or drag file here
        </p>
      </div>
      {errorMessage && <span className="cs-light-body-text-s cs-danger">{errorMessage}</span>}
    </div>
  );
};

// PROPS TYPE
FileUpload.propTypes = {
  onHandleFileUpload: PropTypes.func,
  errorMessage: PropTypes.string,
  setErrorMessage: PropTypes.func,
  multiple: PropTypes.bool
};

export default FileUpload;
