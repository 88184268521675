import * as React from 'react';
import MasterAdminRedirect from '../auth/MasterAdminRedirect';

/* ============================== USER REDIRECT ============================== */
const RedirectUser = {
    path: 'redirect',
    children: [
        {
            path: 'client/:token/:professionalUserId',
            element: <MasterAdminRedirect />
        },
    ]
};

export default RedirectUser;
