import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';

// COMPONENT IMPORTS
import { socket } from '../config/Socket';
import SideBar from './components/SideBar';
import FallBack from '../components/FallBack';
import Header from '../pages/components/Header';
import SkeletonNavbar from './components/skeleton/SkeletonNavbar';
import SkeletonSidebar from './components/skeleton/SkeletonSidebar';

// CSS IMPORTS
import './components/skeleton/SkeletonComponents.css';

// API
import { setActiveNav } from '../slices/systemSlice';
import { allClientsList } from '../slices/clientSlice';
import { authUserLogout, getUserDetails, useUser, useUserID } from '../slices/authSlice';
import {
  ADMIN_USER_SETTINGS_PAGE_PATH,
  STANDARD_USER_SETTINGS_PAGE_PATH
} from '../constants/config';
import { localObjectClear } from '../utils/system';

/* ============================== LAYOUT ============================== */
const Layout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const user = useUser();
  const firm = user?.firm;
  const userId = useUserID();
  const role = user && user.user_role.role;
  const isSidebarOpen = useSelector((state) => state.system.isSidebarOpen);
  const [loading, setLoading] = useState(true);

  const errorHandler = (error, errorInfo) => {
    console.log('error:- ', error);
    console.log('errorInfo:- ', errorInfo);
  };

  useEffect(() => {
    const pathNameArray = pathname.split('/');
    const activePath =
      pathNameArray.length > 2 ? pathNameArray[2] : pathNameArray[pathNameArray.length - 1];
    dispatch(setActiveNav(activePath));

    if (
      (role === 'Standard' && STANDARD_USER_SETTINGS_PAGE_PATH.includes(pathname)) ||
      (role === 'Admin' && ADMIN_USER_SETTINGS_PAGE_PATH.includes(pathname))
    ) {
      navigate('/dashboard');
    }
    const reactClass = window.location.pathname.substring(1).replaceAll("/", "-") || "";
    document.body.classList.add(reactClass);
    return () => {
      document.body.classList.remove(reactClass);
    };

  }, [pathname]);

  const getUserDetailFun = async () => {
    try {
      const res = await dispatch(getUserDetails(`${userId}`));
      if (res) {
        setLoading(false);
      }
      if (res?.payload?.data === null) {
        localObjectClear()
        dispatch(authUserLogout())
        setTimeout(() => {
          window.location.reload()
        }, 300);
      }
    } catch (error) {
      console.log('error :>> ', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserDetailFun();
  }, []);

  useEffect(() => {
    if (
      user?.user_personal_details?.address &&
      pathname !== '/dashboard/set-up' &&
      firm?.firm_brand_details === null
    ) {
      navigate('/dashboard/set-up');
    }
  }, [firm, pathname]);

  useEffect(() => {
    const params = {
      page: 1,
      limit: 10,
      isPaginate: true
    };
    socket.on('verified_user', (msg) => {
      dispatch(allClientsList(params));
    });

    return () => {
      socket.off('verified_user');
    };
  }, []);

  return (
    <ErrorBoundary FallbackComponent={FallBack} onError={errorHandler}>
      <div className={`cs-main-layout ${isSidebarOpen ? 'isOpen' : 'isClose'}`}>
        {loading ? (
          <div className="for-des">
            <SkeletonSidebar />
          </div>
        ) : (
          <SideBar />
        )}
        <div className="cs-content-area">
          {loading ? <SkeletonNavbar /> : <Header />}
          <Outlet context={[loading]} />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Layout;
