import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import secureLocalStorage from 'react-secure-storage';
import { Button, Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';

// COMPONENT IMPORTS
import { formatPhoneNumber, roles } from '../../utils/common';
import { socket } from '../../config/Socket';
import CapsyncIcon from '../../components/CapsyncIcon';
import CustomTable from '../../components/CustomTable';
import AddClientModal from './components/AddClientModal';
import CreateCompanyModal from './components/CreateCompanyModal';
import AddSingleUser from '../../assets/images/add-single-user.svg';
import ViewCompaniesModal from '../Settings/components/ViewCompaniesModal';
import ManageIndividualClientModel from './components/ManageIndividualClientModal';
import AlreadyExistingClientsModal from './components/AlreadyExistingClientsModal';

// API
import {
  addRecentlyViewedIndividual,
  dashboardClientsList,
  resetError
} from '../../slices/clientSlice';

/* ============================== DASHBOARD INDIVIDUAL ============================== */
const DashboardIndividualClient = ({ tab }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const token = secureLocalStorage.getItem('token');
  const userRole = user?.role_id;
  const professionalUserId = user?.id;
  const redirectDomain = user?.firm?.firm_brand_details?.domain;
  const role = user && user.user_role.role;
  const [isModelOpen, setIsModelOpen] = useState({
    addIndividual: false,
    // viewDetails: false,
    viewCompanies: false,
    manageClient: false,
    addClient: false
    // removeUser: false
  });
  const [manageClientModelId, setManageClientModelId] = useState(-1);
  const [clientAdded, setClientAdded] = useState(false);
  const [openIndividualModal, setOpenIndividualModal] = useState(false);
  const [openCompanyModal, setOpenCompanyModal] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const { clientsRecord } = useSelector((state) => state.client);
  const [validationCompany, setValidationCompany] = useState(false);
  const [viewCompaniesData, setViewCompaniesData] = useState();

  const reDirectToClientPage = (clientId) => {
    dispatch(addRecentlyViewedIndividual({ user_id: clientId }));
    socket.emit('addAccess', {
      user_id: professionalUserId,
      access_user_id: clientId
    });
    if (process.env.REACT_APP_MODE == 'LOCAL') {
      window.open(
        `http://${redirectDomain}:3333/redirect/master-admin/${token}/${professionalUserId}/${clientId}/${userRole}`,
        '_self'
      );
    } else {
      window.open(
        `http://${redirectDomain}/redirect/master-admin/${token}/${professionalUserId}/${clientId}/${userRole}`,
        '_self'
      );
    }
  };
  useEffect(() => {
    socket.on('sync_dashboard_count', () => {
      getAllCollaborationUsersList({ type: tab });
    });
    return () => {
      socket.off('sync_dashboard_count', () => {
        getAllCollaborationUsersList({ type: tab });
      });
    };
  }, []);
  useEffect(() => {
    getAllCollaborationUsersList({
      type: tab
    });
    return () => {
      setClientAdded(false);
    };
  }, [clientAdded]);

  const getAllCollaborationUsersList = (params) => {
    dispatch(dashboardClientsList(params));
  };

  const handleOpenModel = (key) => {
    setIsModelOpen({
      ...isModelOpen,
      [key]: true
    });
  };

  const COLUMNS = [
    {
      label: 'First name',
      renderCell: (item) => item.first_name
    },
    {
      label: 'Last name',
      renderCell: (item) => item.last_name
    },
    { label: 'Email', renderCell: (item) => item.email },
    {
      label: 'Phone No.',
      renderCell: (item) => (item && item.phone_no ? formatPhoneNumber(item.phone_no) : '-')
    },
    {
      label: 'Company',
      renderCell: (item) => (
        <label
          className={`${item?.company_details?.length ? 'cs-primary cursor-pointer' : ''}`}
          onClick={() => {
            if (item?.company_details?.length) {
              setViewCompaniesData(item?.company_details);
              handleOpenModel('viewCompanies');
            }
          }}>
          {item?.company_details?.length ? 'View' : '-'}
        </label>
      )
    },
    {
      label: 'User Assigned',
      renderCell: (item) => (
        <span
          className="cursor-pointer cs-primary"
          onClick={() => {
            handleOpenModel('manageClient');
            setManageClientModelId(item.user_id);
            setSelectedData(item);
          }}>
          {role === roles?.standard?.name ? ' View' : 'View & Manage'}
        </span>
      )
    },
    {
      label: 'Date Added',
      renderCell: (item) => moment(item?.createdAt).format('MM/DD/YYYY')
    },
    {
      label: '',
      renderCell: (item) => (
        <div
          className="text-end"
          onClick={() => {
            reDirectToClientPage(item?.user_id);
          }}>
          <span className="cs-neutral-80 cursor-pointer ">
            <CapsyncIcon title="chevron-right-outlined" size="18" />
          </span>
        </div>
      )
    }
  ];

  const handleCloseModel = (key) => {
    if (key === 'addIndividual') {
      getAllCollaborationUsersList({
        page: 1,
        limit: 10
      });
      dispatch(resetError());
    }

    if (key === 'manageClient') {
      setManageClientModelId(-1);
    }

    if (key === 'addClient') {
      return setIsModelOpen({
        ...isModelOpen,
        manageClient: true,
        [key]: false
      });
    }
    setIsModelOpen({
      ...isModelOpen,
      [key]: false
    });
  };

  const handleAddMoreClient = () => {
    setIsModelOpen({
      ...isModelOpen,
      manageClient: false,
      addClient: true
    });
  };

  return (
    <React.Fragment>
      {clientsRecord && clientsRecord.length === 0 ? (
        <div className="dashboard-individual-no-record">
          <span className="cs-common-no-record cs-regular-sub-heading-l cs-neutral-80">
            <span className="no-record-with-img">
              <Image src={AddSingleUser} alt="Add Single User" width={96} height={96} />
            </span>
            Add or import your clients to get started
            <Button
              className="cs-btn-icon-primary xl-btn cs-regular-h5"
              onClick={() => setOpenIndividualModal(true)}>
              <span className="add-filled cs-neutral-10 icon cs-icon">
                <CapsyncIcon title="add-filled" size="18" />
              </span>
              <span>Add Client</span>
            </Button>
          </span>
        </div>
      ) : (
        <CustomTable
          columns={COLUMNS}
          data={clientsRecord ? clientsRecord : []}
          isPaginate={false}
          totalRecords={(clientsRecord && clientsRecord.length) || 0}
        />
      )}
      {isModelOpen.manageClient && (
        <ManageIndividualClientModel
          handleCloseModel={() => handleCloseModel('manageClient')}
          userId={manageClientModelId}
          selectedData={selectedData}
          handleAddMoreClient={handleAddMoreClient}
          fromTable={'individualClient'}
        />
      )}
      {openIndividualModal && (
        <AddClientModal
          handleCloseModel={() => setOpenIndividualModal(false)}
          setOpenIndividualModal={setOpenIndividualModal}
          setOpenCompanyModal={setOpenCompanyModal}
          formSidebar={true}
          setClientAdded={setClientAdded}
        />
      )}
      {openCompanyModal && (
        <CreateCompanyModal
          handleCloseModel={() => setOpenCompanyModal(false)}
          setOpenIndividualModal={setOpenIndividualModal}
          setOpenCompanyModal={setOpenCompanyModal}
          handleOpenModel={() => setValidationCompany(true)}
          formSidebar={true}
        />
      )}
      {validationCompany && (
        <AlreadyExistingClientsModal
          show={validationCompany}
          handleCloseModel={setValidationCompany}
        />
      )}
      {isModelOpen.viewCompanies && (
        <ViewCompaniesModal
          companies={viewCompaniesData}
          handleCancel={() => handleCloseModel('viewCompanies')}
        />
      )}
    </React.Fragment>
  );
};

// PROPS TYPE
DashboardIndividualClient.propTypes = {
  tab: PropTypes.string
};

export default DashboardIndividualClient;
