import React from 'react';
import { Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
const FetchDefaultInstitutionLogo = ({ img }) => {
  let cardIcon;
  switch (img) {
    case 'amex':
      cardIcon = 'american-express';
      break;
    case 'diners':
      cardIcon = 'diners-club';
      break;
    case 'discover':
      cardIcon = 'discover-card';
      break;
    case 'Eftpos Australia':
      cardIcon = 'eftpos';
      break;
    case 'jcb':
      cardIcon = 'jcb-card';
      break;
    case 'mastercard':
      cardIcon = 'master-card';
      break;
    case 'unionpay':
      cardIcon = 'union-pay';
      break;
    case 'visa':
      cardIcon = 'visa-card';
      break;
    case 'unknown':
      cardIcon = 'credit-card';
      break;
    case 'Bank Account':
      cardIcon = 'bank';
      break;
    default:
      cardIcon = 'credit-card';
      break;
  }
  return (
    <Image
      src={require(`../../../../assets/images/bank_logo/${cardIcon}.svg`)}
      className="settings-payment-cardIcon"
    />
  );
};
FetchDefaultInstitutionLogo.propTypes = {
  img: PropTypes.string
};
export default FetchDefaultInstitutionLogo;
