import React from 'react';
import { useFirmPaymentDetails } from '../../../../slices/billingHistorySlice';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';

const BillingDateComponent = () => {
  const firmPaymentDetails = useFirmPaymentDetails();
  return (
    <div className="billing-box">
      <h4 className="cs-regular-h4 cs-neutral-100">Billing date</h4>
      <div className="cs-flex">
        <div className="last-billing">
          <span className="cs-regular-h4 cs-neutral-70">Last billing</span>
          <h2 className="cs-semi-bold-h1 cs-primary">
            {firmPaymentDetails?.start_date
              ? moment(firmPaymentDetails?.start_date).format('L')
              : <Skeleton width={100} />}
          </h2>
        </div>
        <span className="cs-neutral-100">
          <svg
            width="40"
            height="22"
            viewBox="0 0 40 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M39.7298 10.3617L30.6391 1.2709C30.3125 0.889582 29.7386 0.84513 29.3573 1.17177C28.976 1.49832 28.9315 2.07222 29.2582 2.45354C29.2886 2.48909 29.3217 2.52229 29.3573 2.55268L36.8935 10.098H0.903187C0.401165 10.098 -0.00585938 10.505 -0.00585938 11.0071C-0.00585938 11.5092 0.401165 11.9162 0.903187 11.9162H36.8935L29.3573 19.4524C28.976 19.7789 28.9315 20.3528 29.2582 20.7342C29.5848 21.1155 30.1586 21.1599 30.5399 20.8333C30.5755 20.8028 30.6087 20.7697 30.6391 20.7342L39.7298 11.6434C40.0823 11.2889 40.0823 10.7163 39.7298 10.3617Z"
              fill="var(--neutral-100)"
            />
          </svg>
        </span>
        <div className="next-billing">
          <span className="cs-regular-h4 cs-neutral-70">Next billing</span>
          <h2 className="cs-semi-bold-h1 cs-primary">
            {firmPaymentDetails?.start_date
              ? moment(firmPaymentDetails?.end_date).format('L')
              : <Skeleton width={100} />}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default BillingDateComponent;
