import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import Modal from '../../../../components/Modal';
import { useUser } from '../../../../slices/authSlice';
import useDebounce from '../../../../hooks/useDebounce';
import { formatNumberWithThousandSeparator } from '../../../../utils/common';

// COMPONENT IMPORT
import SummaryTable from './SummaryTable';
import CustomTable from '../../../../components/CustomTable';
import InputBox from '../../../../components/FormFields/InputBox';
import ProcessBarBillingHistory from './ProcessBarBillingHistory';
import SearchBox from '../../../../components/FormFields/SearchBox';
import DropdownBox from '../../../../components/FormFields/DropdownBox';

// API
import {
  createIntentForCredit,
  getFirmCreditList,
  getFirmPaymentDetails,
  getVerificationPaymentList,
  updateFirmSubscriptionPaymentQty,
  useFirmCreditList,
  useFirmPaymentDetails,
  usePlansList
} from '../../../../slices/billingHistorySlice';
import { allClientsList } from '../../../../slices/clientSlice';
import { allUsersList } from '../../../../slices/collaborationSlice';


const CommonPurchaseModal = ({
  type,
  purchaseModalOpen,
  setCardModalType,
  setPurchaseModalOpen,
  setShowStripeForm
}) => {
  const initialValues = {
    professionalLicenses: 0,
    clientLicenses: 0,
    individuals: 0,
    qualifiedclient: 0,
    qualifiedpurchaser: 0
  };
  const COLUMNS_ONE = [
    {
      label: 'Name',
      renderCell: (item) => item.display_name,
      width: '210',
      key: 'display_name'
    },
    {
      label: 'Role',
      renderCell: (item) => <span>{item?.role_name}</span>,
      width: '210',
      key: 'role_name'
    }
  ];
  const COLUMNS_ONE_CLIENT = [
    {
      label: 'Name',
      renderCell: (item) => item.display_name,
      width: '210',
      key: 'display_name'
    },
    {
      label: 'Date',
      renderCell: (item) => <span>{moment(item?.createdAt).format('L')}</span>,
      width: '210',
      key: 'createdAt'
    }
  ];

  const COLUMNS_CREDIT = [
    {
      label: 'Name',
      renderCell: (item) => item.display_name,
      width: '210',
      key: 'display_name'
    },
    {
      label: 'Date',
      renderCell: (item) => {
        return (
          <span>{item.request_created_at ? moment(item.request_created_at).format('L') : '-'}</span>
        );
      },
      width: '210',
      key: 'request_created_at'
    }
  ];

  const options = [
    { label: 'Accredited Investor', value: '1', key: 'individuals' },
    { label: 'Qualified Client', value: '2', key: 'qualifiedclient' },
    { label: 'Qualified Purchaser', value: '3', key: 'qualifiedpurchaser' }
  ];
  const checkLicensesOptions = [
    { label: 'Professional Licenses', value: '1', key: 'individuals' },
    { label: 'Client Licenses', value: '2', key: 'qualifiedclient' }
  ];
  const COLUMNS_TWO = [
    { label: 'Description', renderCell: (item) => item.display_name, width: '210' },
    { label: 'Qty', renderCell: (item) => item.quantity, width: '210' },
    {
      label: 'Price',
      renderCell: (item) => formatNumberWithThousandSeparator(item.price),
      width: '210'
    }
  ];

  const purchaseTableSorting = {
    display_name: { key: 'display_name', showIcon: false, title: 'Name', sortOrder: 'asc' },
    role_name: { key: 'role_name', showIcon: true, title: 'Role', sortOrder: 'desc' }
  };
  const purchaseTableSortingTwo = {
    display_name: { key: 'display_name', showIcon: false, title: 'Name', sortOrder: 'asc' },
    createdAt: { key: 'createdAt', showIcon: true, title: 'Date', sortOrder: 'desc' }
  };

  const creditListSorting = {
    display_name: { key: 'display_name', showIcon: false, title: 'Name', sortOrder: 'asc' },
    request_created_at: {
      key: 'request_created_at',
      showIcon: true,
      title: 'Date',
      sortOrder: 'desc'
    }
  };

  const user = useUser();
  const dispatch = useDispatch();
  const planList = usePlansList();
  const firmPaymentDetails = useFirmPaymentDetails();
  const firmCreditList = useFirmCreditList();
  const [selectedOption, setSelectedOption] = useState({
    label: 'Accredited Investor',
    value: '1',
    key: ''
  });
  const [selectedCheckLicenses, setSelectedCheckLicenses] = useState({
    label: 'Professional Licenses',
    value: '1',
    key: ''
  });
  const [searchValue, setSearchValue] = useState('');
  const [showTableData, setShowTableData] = useState([]);
  const [shortingFun, setShortingFun] = useState({ ...purchaseTableSorting });
  const [shortingFunClient, setShortingFunClient] = useState({ ...purchaseTableSortingTwo });
  const [creditListShortingFun, setCreditListShortingFun] = useState({
    ...creditListSorting
  });
  const [isLoading, setIsLoading] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [creditList, setCreditList] = useState([]);

  const purchaseSortingData = (listData = [], sortingHeader) => {
    if (listData.length > 0) {
      const sortingKey = Object.keys(sortingHeader).find((key) => sortingHeader[key].showIcon);

      if (sortingKey) {
        const { sortOrder } = sortingHeader[sortingKey];
        const sortedData = [...listData].sort((a, b) => {
          if (a[sortingKey] < b[sortingKey]) {
            return sortOrder === 'asc' ? -1 : 1;
          }
          if (a[sortingKey] > b[sortingKey]) {
            return sortOrder === 'asc' ? 1 : -1;
          }
          return 0;
        });

        return sortedData;
      }
    }
    return listData;
  };


  useEffect(() => {
    if (selectedCheckLicenses.value === "1") {
      const newData = purchaseSortingData(usersList, shortingFun);
      setSortedData(newData);
    } else {
      const newData = purchaseSortingData(usersList, shortingFunClient);
      setSortedData(newData);
    }
  }, [shortingFun, shortingFunClient, usersList]);

  useEffect(() => {
    const newData = purchaseSortingData(firmCreditList?.creditList, creditListShortingFun);
    setCreditList(newData);
  }, [creditListShortingFun, firmCreditList?.creditList]);

  const { values, errors, touched, setValues, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        if (type === 'accreditation') {
          const payload = {
            firm_id: user?.firm_id,
            amount: Number(showTableData?.reduce((acc, items) => acc + items?.price, 0)),
            accredited_investor_credit: Number(values?.individuals),
            qualified_purchaser_credit: Number(values?.qualifiedpurchaser),
            qualified_client_credit: Number(values?.qualifiedclient)
          };
          const response = await dispatch(createIntentForCredit(payload)).unwrap();
          if (response?.status) {
            setCardModalType('CommonPurchaseModal');
            setShowStripeForm(true);
            setPurchaseModalOpen(false);
          } else {
            toast.error(response?.message);
          }
        } else if (type === 'users') {
          const payload = {
            subscription_id: firmPaymentDetails?.subscription_id,
            client_pice_id: firmPaymentDetails?.monthly_client_fee_price_id,
            team_user_pice_id: firmPaymentDetails?.monthly_team_member_fee_price_id,
            client_count: values?.clientLicenses,
            team_member_count: values?.professionalLicenses
          };
          const response = await dispatch(updateFirmSubscriptionPaymentQty(payload)).unwrap();
          if (response?.status) {
            dispatch(getFirmPaymentDetails({ firm_id: user?.firm_id }));
            setPurchaseModalOpen(false);
            toast?.success('New Licenses Purchased');
          } else {
            toast.error(response?.errors);
            toast.error(response?.message);
          }
        } else if (type === 'checkLicenses' || type === 'clearHistory') {
          setPurchaseModalOpen(false);
        }
      } catch (error) {
        console.log('error :>> ', error);
      } finally {
        setValues(initialValues);
        setIsLoading(false);
      }
    }
  });

  const handleChangeSelect = (selectedValue) => {
    const selected = options.find((option) => option.value === selectedValue);
    setSelectedOption(selected);
  };
  const handleChangeLicensesSelect = (selectedValue) => {
    setSearchValue('');
    const selected = checkLicensesOptions.find((option) => option.value === selectedValue);
    setSelectedCheckLicenses(selected);
  };
  const getFirmTransaction = async (params) => {
    await dispatch(getFirmCreditList(params)).unwrap();
  };
  const onPaginationValueChange = (option) => {
    const paginationLimits = {
      firm_id: user?.firm_id,
      verification_type: selectedOption?.label || null
    };
    getFirmTransaction(paginationLimits);
  };
  const getAllCollaborationUsersList = async (params) => {
    const response =
      selectedCheckLicenses.label === 'Professional Licenses'
        ? await dispatch(allUsersList(params)).unwrap()
        : await dispatch(allClientsList(params)).unwrap();
    const verifiedUsers =
      selectedCheckLicenses.label === 'Professional Licenses'
        ? response?.records
        : response?.data?.records;
    setUsersList(verifiedUsers);
  };
  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
    onHandleSearchData(e.target.value);
  };
  const onHandleSearchData = useDebounce((params) => {
    if (type === 'checkLicenses') {
      getAllCollaborationUsersList({
        search: params,
        isPaginate: false
      });
    } else {
      getFirmTransaction({
        firm_id: user?.firm_id,
        search: params,
        isPaginate: false,
        verification_type: selectedOption?.label || null
      });
    }
  }, 300);

  const getCreditUsageInfo = (firmCreditList, selectedOption) => {
    const creditMapping = {
      'Accredited Investor': 'accreditation_accredited_investor_credit',
      'Qualified Client': 'accreditation_qualified_client_credit',
      'Qualified Purchaser': 'accreditation_qualified_purchaser_credit'
    };

    const creditType = creditMapping[selectedOption.label];
    const creditUsed = creditType ? firmCreditList?.firmCredit?.[creditType] : 0;
    const itemCount = firmCreditList?.count?.itemCount || 0;

    return { itemCount, creditUsed };
  };
  useEffect(() => {
    if (Array.isArray(planList) && type === 'accreditation') {
      const setData = planList.map((items) => {
        return {
          display_name: items?.title,
          quantity: values[items?.type] || 0,
          price: items?.amount * values[items?.type] || 0
        };
      });
      setShowTableData([...setData]);
    } else if (type === 'users') {
      const setData = [
        {
          display_name: 'Professional licenses',
          quantity: values?.professionalLicenses,
          price: firmPaymentDetails?.monthly_team_member_fee_price * values?.professionalLicenses
        },
        {
          display_name: 'Client licenses',
          quantity: values?.clientLicenses,
          price: firmPaymentDetails?.monthly_client_fee_price * values?.clientLicenses
        }
      ];
      setShowTableData([...setData]);
    } else if (type === 'checkLicenses') {
      getAllCollaborationUsersList({
        isPaginate: false,
        include_logged_user: true
      });
    }
  }, [values, purchaseModalOpen, selectedCheckLicenses, firmPaymentDetails, planList]);

  useEffect(() => {
    if (purchaseModalOpen && type === 'accreditation') {
      dispatch(getVerificationPaymentList()).unwrap();
    }
  }, [purchaseModalOpen]);

  useEffect(() => {
    onPaginationValueChange({
      firm_id: user?.firm_id,
      verification_type: selectedOption?.label || null
    });
  }, [selectedOption]);
  const planAmount = (moduleName) => {
    if (Array.isArray(planList)) {
      const amount = planList?.find((items) => items?.title === moduleName)?.amount;
      return amount;
    }
    return 0;
  };

  const closeModal = () => {
    setPurchaseModalOpen(false);
    setValues(initialValues);
  };

  const modalBody = () => {
    const creditUsageInfo = getCreditUsageInfo(firmCreditList, selectedOption);
    switch (type) {
      case 'users':
        return (
          <React.Fragment>
            <div
              className="cs-datafield"
              data-title={`$ ${firmPaymentDetails?.monthly_team_member_fee_price || 0}`}>
              <InputBox
                label="Professional licenses"
                name="professionalLicenses"
                placeholder="Enter Professional licenses"
                type="number"
                values={values}
                max={4}
                errors={errors}
                touched={touched}
                onChange={handleChange}
              />
            </div>
            <div
              className="cs-datafield"
              data-title={`$ ${firmPaymentDetails?.monthly_client_fee_price || 0}`}>
              <InputBox
                label="Client licenses"
                name="clientLicenses"
                placeholder="Enter Client licenses"
                type="number"
                max={4}
                values={values}
                errors={errors}
                touched={touched}
                onChange={handleChange}
              />
            </div>
            <SummaryTable
              columns={COLUMNS_TWO}
              data={showTableData}
              showTableData={showTableData}
            />
          </React.Fragment>
        );

      case 'accreditation':
        return (
          <React.Fragment>
            <div className="cs-datafield" data-title={`$ ${planAmount('Accredited Investor')}`}>
              <InputBox
                label="Accredited Investor"
                name="individuals"
                placeholder="Enter licenses"
                type="number"
                max={4}
                values={values}
                errors={errors}
                touched={touched}
                onChange={handleChange}
              />
            </div>
            <div className="cs-datafield" data-title={`$ ${planAmount('Qualified Client')}`}>
              <InputBox
                label="Qualified Client"
                name="qualifiedclient"
                placeholder="Enter Qualified Client"
                type="number"
                max={4}
                values={values}
                errors={errors}
                touched={touched}
                onChange={handleChange}
              />
            </div>
            <div className="cs-datafield" data-title={`$ ${planAmount('Qualified Purchaser')}`}>
              <InputBox
                label="Qualified Purchaser"
                name="qualifiedpurchaser"
                placeholder="Enter Qualified Purchaser"
                type="number"
                max={4}
                values={values}
                errors={errors}
                touched={touched}
                onChange={handleChange}
              />
            </div>
            <SummaryTable
              columns={COLUMNS_TWO}
              data={showTableData}
              showTableData={showTableData}
            />
          </React.Fragment>
        );

      case 'clearHistory':
        return (
          <React.Fragment>
            <DropdownBox
              name="customDropdown"
              options={options}
              value={selectedOption}
              tooltip={false}
              placeholder="Select an option"
              label="Choose Verification Type"
              className="custom-class"
              handleChangeSelect={handleChangeSelect}
              disabled={false}
            />
            <ProcessBarBillingHistory
              type="credit"
              label={selectedOption?.label}
              used={creditUsageInfo?.itemCount}
              total={creditUsageInfo?.creditUsed}
            />
            <SearchBox value={searchValue} placeholder={'Search'} onChange={onSearchChange} />
            <CustomTable
              className="cs-checkbox-table"
              sorting={true}
              columns={COLUMNS_CREDIT}
              setShortingFun={setCreditListShortingFun}
              shortingFun={creditListShortingFun}
              totalRecords={creditList?.length}
              data={creditList}
              onPaginationValueChange={onPaginationValueChange}
              isPaginate={false}
            />
          </React.Fragment>
        );
      case 'checkLicenses':
        return (
          <>
            <DropdownBox
              name="customDropdown"
              options={checkLicensesOptions}
              value={selectedCheckLicenses}
              tooltip={false}
              placeholder="Select an option"
              label="Choose Licenses Type"
              className="custom-class"
              handleChangeSelect={handleChangeLicensesSelect}
              disabled={false}
            />
            <ProcessBarBillingHistory
              type="licenses"
              label={selectedCheckLicenses.label}
              used={
                selectedCheckLicenses.label === 'Professional Licenses'
                  ? firmPaymentDetails?.total_available_firm_users
                  : firmPaymentDetails?.total_available_client
              }
              total={
                selectedCheckLicenses.label === 'Professional Licenses'
                  ? firmPaymentDetails?.total_team_member
                  : firmPaymentDetails?.total_client
              }
            />
            <SearchBox value={searchValue} placeholder={'Search'} onChange={onSearchChange} />
            <CustomTable
              className="cs-checkbox-table"
              parentClassName="cs-purchase-table"
              sorting={true}
              setShortingFun={selectedCheckLicenses.value === "1" ? setShortingFun : setShortingFunClient}
              shortingFun={selectedCheckLicenses.value === "1" ? shortingFun : shortingFunClient}
              columns={selectedCheckLicenses.value === "1" ? COLUMNS_ONE : COLUMNS_ONE_CLIENT}
              totalRecords={sortedData?.length}
              data={sortedData}
              onPaginationValueChange={onPaginationValueChange}
              isPaginate={false}
            />
          </>
        );
      default:
        null;
    }
  };

  return (
    <Modal
      className="stripe-form cs-md-modal"
      show={purchaseModalOpen}
      title={
        type === 'users'
          ? 'Purchase new licenses'
          : type === 'accreditation'
            ? 'Purchase new credits'
            : type === 'clearHistory'
              ? 'Clear used credit'
              : type === 'checkLicenses'
                ? 'Check Licenses'
                : ''
      }
      body={modalBody()}
      btnSize={type === 'users' ? 'lg-btn' : 'md-btn'}
      saveButtonLabel={
        type === 'users' ? 'Add Subscription' : type === 'accreditation' ? 'Continue' : 'Okay'
      }
      cancelButtonLabel={type === 'users' || type === 'accreditation' ? 'Cancel' : ''}
      handleOnSave={handleSubmit}
      loading={isLoading}
      handleOnCancel={closeModal}
      disabled={
        ((type === 'accreditation' || type === 'users') &&
          Number(showTableData?.reduce((acc, items) => acc + items?.price, 0) === 0)) ||
        isLoading
      }
      isCloseButton={false}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="cs-md-modal"
    />
  );
};

CommonPurchaseModal.propTypes = {
  type: PropTypes.string,
  purchaseModalOpen: PropTypes.bool,
  setCardModalType: PropTypes.func,
  setPurchaseModalOpen: PropTypes.func,
  setShowStripeForm: PropTypes.func
};

export default CommonPurchaseModal;
