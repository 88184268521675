import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import { useSelector } from 'react-redux';

/* ============================== PHONE INPUT MODAL ============================== */
const PhoneInputModal = ({ show, setShow, handleGetCodeClick }) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <Modal
      show={show}
      size="md"
      backdrop="static"
      centered
      dialogClassName="cs-md-modal cs-modal-multi-button cs-verification-modal">
      <Modal.Header>
        <Modal.Title className="cs-semi-bold-h3 cs-neutral-100">Mobile verification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className="cs-regular-h5 text-center cs-neutral-80">
          Enter your mobile number to proceed further
        </h5>
        <Form className="last-form-field cs-form-group">
          <PhoneInput
            limitMaxLength={15}
            international
            countryCallingCodeEditable={false}
            placeholder="Phone Number"
            name="phone"
            value={user && user.phone_no}
            withCountryCallingCode={false}
            className="form-control"
            defaultCountry="US"
            countries={['US']}
            disabled={true}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cs-btn-secondary md-btn cs-regular-h5" onClick={() => setShow(false)}>
          Cancel
        </Button>
        <Button className="cs-btn-primary md-btn cs-regular-h5" onClick={handleGetCodeClick}>
          Get code
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// PROPS TYPE
PhoneInputModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  handleGetCodeClick: PropTypes.func.isRequired
};

export default PhoneInputModal;
