import Axios from 'axios';
import { toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';

// COMPONENT IMPORTS
import CONFIG from '../constants/config';
import { localStorageClear } from '../utils/common';

/* ============================== API ============================== */
const axios = Axios.create({});

axios.defaults.timeout = 120000; // Milliseconds

axios.interceptors.request.use(
  async function (config) {
    const token = secureLocalStorage.getItem('token');

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      config.headers['Access-Control-Allow-Credentials'] = true;
    }
    config.credentials = 'same-origin';
    config.baseURL = CONFIG.BASE_URL;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => {
    if (res?.data?.code === 401) {
      toast.error(res?.data?.message);
      localStorageClear();
      setTimeout(() => {
        window.location.href = '/';
      }, 1000);
    }
    return res.data;
  },
  (error) => {
    if (error?.response?.status === 403) {
      // Handle forbidden error
    }
    if (error?.response?.status === 401) {
      // Handle unauthorized error (e.g., log out the user)
    }
    throw error; // Propagate the error
  }
);

export default axios;
