import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Container, Row, Image, Form, Button } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncIcon from '../../components/CapsyncIcon';
import TempImage from '../../assets/images/brand-setup/template-design/template-img/temp-image.png';
import TempImage4 from '../../assets/images/brand-setup/template-design/template-img/temp-image-4.png';

// CSS IMPORTS
import './branding.css';

/* ============================== PREVIEW TEMPLATE ============================== */
const PreviewTemplate = ({
  logo,
  selectedImg,
  selectedTemplate,
  welcomeMsg,
  welcomeMsgDescription,
  isChecked
}) => {
  return (
    <div className="preview-brand">
      <section
        className={`auth-section choose-temp-design ${selectedTemplate === '' ? 'template1-section' : `template${selectedTemplate}-section`}`}>
        <Container fluid>
          <Row className="align-items-center">
            {selectedTemplate !== '2' && (
              <Col>
                <div className="template-left-side">
                  <div className="template-bg">
                    <Image
                      width="490"
                      height="550"
                      className="cs-logo"
                      src={
                        selectedImg?.link
                          ? selectedImg?.link
                          : selectedTemplate === '4'
                            ? TempImage4
                            : TempImage
                      }
                      alt="Capsync Image"
                    />
                    {isChecked && (
                      <div className="welcome-text">
                        <h4 className="cs-primary">
                          {welcomeMsg
                            ? welcomeMsg
                            : 'Your New Omni-Channel Financial Verification Hub'}
                        </h4>
                        <p className="cs-medium-sub-heading-xxs cs-neutral-100">
                          {welcomeMsgDescription
                            ? welcomeMsgDescription
                            : 'Empowering Financial Decisions with Real-Time, Verified Data'}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            )}
            <Col>
              <div className="template-right-section">
                <div className="cs-auth-header">
                  <Image className="cs-logo" width="68" src={logo} alt="Capsync Logo" />
                  <div className="cs-title">
                    <h1 className="cs-semi-bold-h1">Sign in</h1>
                  </div>
                </div>
                <div className="cs-auth-form">
                  <Form>
                    <Row>
                      <Col lg={12}>
                        <Form.Group className="cs-form-group">
                          <Form.Control type="email" placeholder="Email address" />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div className="last-form-field">
                          <Form.Group className="cs-form-group">
                            <Form.Control type="password" placeholder="Password" />
                            <span className="input-field-icon cursor-pointer cs-neutral-80 password-info">
                              <CapsyncIcon title="hide-password-outlined" size="12" />
                            </span>
                          </Form.Group>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} className="text-end">
                        <Link className="cs-primary cs-regular-sub-heading-m" to="/forgot-password">
                          Forgot password?
                        </Link>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="auth-footer">
                          <Button type="submit" className="cs-btn-primary lg-btn cs-regular-h5">
                            Sign in
                          </Button>

                          <Form.Group>
                            <div className="cs-regular-body-text-m">
                              <span
                                htmlFor="termsCheckbox"
                                className="cs-light-body-text-m cs-neutral-90">
                                By selecting Sign in, you agree'to our&nbsp;
                                <Link
                                  to="https://capsync.com/terms-of-service/"
                                  className="cs-primary cs-regular-body-text-m cs-link"
                                  target="_Blank">
                                  Terms of Service
                                </Link>
                                &nbsp;and acknowledge our&nbsp;
                                <Link
                                  to="https://capsync.com/privacy-policy/"
                                  className="cs-primary cs-regular-body-text-m cs-link"
                                  target="_Blank">
                                  Privacy Policy.
                                </Link>
                              </span>
                            </div>
                          </Form.Group>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

// PROPS TYPE
PreviewTemplate.propTypes = {
  logo: PropTypes.string,
  selectedImg: PropTypes.object,
  selectedTemplate: PropTypes.string,
  welcomeMsg: PropTypes.string,
  welcomeMsgDescription: PropTypes.string,
  isChecked: PropTypes.bool
};

export default PreviewTemplate;
