import React, { useState, useEffect } from 'react';
import { Accordion, Button, Col, Form, Image, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

// COMPONENT IMPORTS
import CapsyncIcon from '../../components/CapsyncIcon';
import DeleteModal from '../../components/DeleteModal';
import Template1 from '../../assets/images/brand-setup/template-design/template-img/template-1.svg';
import Template2 from '../../assets/images/brand-setup/template-design/template-img/template-2.svg';
import Template3 from '../../assets/images/brand-setup/template-design/template-img/template-4.svg';
import Template4 from '../../assets/images/brand-setup/template-design/template-img/template-3.svg';

// API
import { useUserID } from '../../slices/authSlice';
import { deleteUploadImage } from '../../slices/brandingSlice';

/* ============================== CLIENT LOGIN PAGE ============================== */
const ClientLogin = (props) => {
  const {
    clientLoginPage,
    activeKey,
    handleClickItem,
    isChecked,
    toggleMessage,
    handleFileInputChange,
    setResetClientLogin,
    inputRef,
    handleFileUploadButtonClick,
    error,
    settings,
    selectedImg,
    imgArray = [],
    mouseDownCoords,
    clickOrDrag,
    selectedTemplate,
    clickOrDragTemplate,
    fileInput,
    setSelectedImg,
    setSelectedTemplate,
    setDeletedImg,
    isUploading,
    setIsDeleting,
    isDeleting,
    brandDetails
  } = props;

  const dispatch = useDispatch();

  const userId = useUserID();
  const { firm } = useSelector((state) => state.auth.user);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [resetWelcomeMessage, setResetWelcomeMessage] = useState(false);
  const [index, setIndex] = useState('');

  const templateArray = [
    {
      link: Template1,
      index: '1'
    },
    {
      link: Template2,
      index: '2'
    },
    {
      link: Template3,
      index: '3'
    },
    {
      link: Template4,
      index: '4'
    }
  ];

  const mouseDownCoordsTemplate = (e) => {
    window.checkForDrag = e.clientX;
  };
  const handleResetImage = () => {
    setSelectedImg(imgArray[imgArray.length - 1]);
  };

  const handleResetTemplate = () => {
    setSelectedTemplate('');
  };

  const handleUploadImgDelete = () => {
    setIsDeleting(true);
    if (
      firm &&
      firm.firm_brand_details &&
      firm.firm_brand_details.welcome_image &&
      index.name === firm.firm_brand_details.welcome_image
    ) {
      toast.error('Active selected image cannot be deleted');
      setShowDeleteModal(false);
      setIndex('');
      setIsDeleting(false);
    } else if (index.id) {
      dispatch(deleteUploadImage({ id: index.id, userId: userId })).then((res) => {
        if (res?.payload?.code === 200) {
          setDeletedImg(index);
          brandDetails();
          toast.success(res.payload.message);
          setShowDeleteModal(false);
          setIndex('');
        } else {
          toast.error(res.payload.message);
        }
      });
    }
  };

  const handleCloseModel = () => {
    setShowDeleteModal(false);
  };

  const handleResetWelcomeMessage = () => {
    setResetClientLogin(true);
    setResetWelcomeMessage(true);
  };

  useEffect(() => {
    if (resetWelcomeMessage && firm && firm.firm_brand_details) {
      clientLoginPage.values.welcome_msg = 'Your New Immersive Digital Financial Hub';
      clientLoginPage.values.welcome_msg_description =
        'Empowering Financial Decisions with Real-Time, Verified Data';
    }
    return () => {
      setResetWelcomeMessage(false);
    };
  }, [resetWelcomeMessage]);


  return (
    <Form onSubmit={clientLoginPage.handleSubmit} id="client-login">
      <Accordion.Item
        eventKey="3"
        className={
          activeKey == 3 ? 'client-login-section cs-accordion-active' : 'client-login-section'
        }>
        <Accordion.Header onClick={() => handleClickItem('3')}>
          <span className="cs-regular-h3 cs-neutral-100">Client Login Page</span>
        </Accordion.Header>
        <Accordion.Body>
          <p className="cs-light-body-text-l cs-neutral-80">
            Choose your preferred home screen layout. You can update this anytime from your settings
            page.
          </p>
          <Row>
            <Col lg={12}>
              <div className="welcome-message-container">
                <div className="welcome-message-box">
                  <h3 className="cs-regular-h3 cs-neutral-90">
                    Welcome Message &nbsp;
                    {firm && firm.firm_brand_details && (
                      <span
                        className="cs-regular-h5 cs-primary cursor-pointer"
                        onClick={handleResetWelcomeMessage}>
                        Reset
                      </span>
                    )}
                  </h3>
                  <div className="cs-switch">
                    <input
                      className="cs-switch-checkbox"
                      id={`switch`}
                      type="checkbox"
                      checked={isChecked}
                      onChange={toggleMessage}
                      disabled={!isChecked && selectedTemplate ==='2'}
                    />
                    <label className="cs-switch-label" htmlFor={`switch`}>
                      <span className="switch-button" />
                    </label>
                  </div>
                </div>
                {isChecked && (
                  <React.Fragment>
                    <Form.Group className="cs-form-group">
                      <Form.Control
                        type="text"
                        className=" cs-input-field-active"
                        placeholder="Your New Omni-Channel Financial Verification Hub"
                        name="welcome_msg"
                        value={clientLoginPage.values.welcome_msg}
                        onChange={clientLoginPage.handleChange}
                      />
                      {isChecked &&
                      clientLoginPage.errors.welcome_msg &&
                      clientLoginPage.touched.welcome_msg ? (
                        <span className="form-error-msg cs-light-body-text-s cs-danger">
                          {clientLoginPage.errors.welcome_msg}
                        </span>
                      ) : null}
                    </Form.Group>
                    <Form.Group className="cs-form-group cs-form-textarea">
                      <textarea
                        autoComplete="off"
                        type="text"
                        placeholder="Empowering Financial Decisions with Real-Time, Verified Data"
                        className="cs-textarea cs-input-field-active cs-textarea"
                        name="welcome_msg_description"
                        value={clientLoginPage.values.welcome_msg_description}
                        onChange={clientLoginPage.handleChange}></textarea>
                      {isChecked &&
                      clientLoginPage.errors.welcome_msg_description &&
                      clientLoginPage.touched.welcome_msg_description ? (
                        <span className="form-error-msg cs-light-body-text-s cs-danger">
                          {clientLoginPage.errors.welcome_msg_description}
                        </span>
                      ) : null}
                    </Form.Group>
                  </React.Fragment>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="cs-form-group">
                <Form.Label>Upload an image</Form.Label>
                <div className="cs-choose-file">
                  <div className="cs-choose-file-box">
                    <Form.Control
                      type="file"
                      accept="image/*"
                      onChange={handleFileInputChange}
                      ref={inputRef}
                      disabled={isUploading}
                    />
                    <span className="cs-icon cs-neutral-10">
                      <CapsyncIcon title="upload-outlined" size="18" />
                    </span>
                    {error ? (
                      <span className="form-error-msg cs-light-body-text-s cs-danger">{error}</span>
                    ) : (
                      <span className="form-error-msg cs-light-body-text-s cs-neutral-90">
                        Image must be 1920x1080
                      </span>
                    )}
                  </div>
                  <Button
                    className="cs-btn-primary sm-btn cs-regular-h5"
                    onClick={() => handleFileUploadButtonClick()}
                    disabled={error || fileInput === '' || isUploading}>
                    Upload
                    {isUploading && (
                      <span className="cs-common-spinner cs-primary">
                        <CapsyncIcon title="loading-outlined" size="18" />
                      </span>
                    )}
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="choose-image-slider">
                <div className="welcome-message-box">
                  <h3 className="cs-regular-h3 cs-neutral-100">Choose an image</h3>
                  <h5
                    className="cs-regular-h5 cs-primary cursor-pointer"
                    onClick={handleResetImage}>
                    Reset
                  </h5>
                </div>
                {imgArray && imgArray?.length > 0 ? (
                  <Slider className="cs-slider" {...settings}>
                    {imgArray.map((imgObj, index) => (
                      <div
                        key={index}
                        className={`image-box ${selectedImg?.name === imgObj.name ? 'selected-slider image-container' : 'image-container'}`}>
                        <Image
                          src={imgObj.link}
                          alt=""
                          onMouseDown={(e) => mouseDownCoords(e)}
                          onMouseUp={(e) => clickOrDrag(e, imgObj)}
                        />
                        {!(
                          imgObj?.id <= 4 ||
                          imgObj?.name === 'Image 1.png' ||
                          imgObj?.name === 'Image 2.png' ||
                          imgObj?.name === 'Image 3.png' ||
                          imgObj?.name === 'Image 4.png'
                        ) && (
                          <span
                            className={
                              isDeleting ? 'delete-icon cs-disabled' : 'delete-icon cs-danger'
                            }
                            onClick={(e) => {
                              if (isDeleting) return;
                              e.stopPropagation();
                              setIndex(imgObj);
                              setShowDeleteModal(true);
                            }}>
                            <CapsyncIcon title="delete-outlined" size="14" />
                          </span>
                        )}
                      </div>
                    ))}
                  </Slider>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="choose-image-slider choose-template-slider">
                <div className="welcome-message-box">
                  <h3 className="cs-regular-h3 cs-neutral-100">Choose template</h3>
                  <h5
                    className="cs-regular-h5 cs-primary cursor-pointer"
                    onClick={handleResetTemplate}>
                    Reset
                  </h5>
                </div>
                <Slider className="cs-slider" {...settings}>
                  {templateArray.map((tempObj, index) => (
                    <div
                      key={index}
                      className={`image-box ${selectedTemplate == tempObj.index ? 'selected-slider' : ''}`}
                      onMouseDown={(e) => mouseDownCoordsTemplate(e)}
                      onMouseUp={(e) => clickOrDragTemplate(e, tempObj)}>
                      <img src={tempObj.link} alt="" />
                    </div>
                  ))}
                </Slider>
              </div>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
      {showDeleteModal && (
        <DeleteModal
          show={showDeleteModal}
          onHandleClose={() => handleCloseModel()}
          onHandleConfirm={handleUploadImgDelete}
          deleteBodyText="Are you sure you want to delete this image? Once deleted, this action cannot be reversed"
          deleteButtonText="Delete"
        />
      )}
    </Form>
  );
};

// PROPS TYPE
ClientLogin.propTypes = {
  clientLoginPage: PropTypes.object,
  activeKey: PropTypes.string,
  handleClickItem: PropTypes.func,
  isChecked: PropTypes.bool,
  toggleMessage: PropTypes.func,
  handleFileInputChange: PropTypes.func,
  inputRef: PropTypes.object,
  handleFileUploadButtonClick: PropTypes.func,
  error: PropTypes.string,
  settings: PropTypes.object,
  imgArray: PropTypes.array,
  selectedImg: PropTypes.object,
  mouseDownCoords: PropTypes.func,
  clickOrDrag: PropTypes.func,
  setSelectedTemplate: PropTypes.func,
  setResetClientLogin: PropTypes.func,
  domainNameValue: PropTypes.string,
  logoDetails: PropTypes.object,
  selectedTemplate: PropTypes.string,
  clickOrDragTemplate: PropTypes.func,
  fileInput: PropTypes.string,
  setSelectedImg: PropTypes.func,
  brandDetails: PropTypes.func,
  setDeletedImg: PropTypes.func,
  setIsDeleting: PropTypes.func,
  isUploading: PropTypes.bool,
  isDeleting: PropTypes.bool,
  domainName: PropTypes.object,
  primaryColor: PropTypes.string
};

export default ClientLogin;
