import React from 'react';
import PropTypes from 'prop-types';
import { useFirmPaymentDetails } from '../../../../slices/billingHistorySlice';
import { Button } from 'react-bootstrap';

const CurrentMonthlyBill = ({ setShowUpgradeModal }) => {
  const firmPaymentDetails = useFirmPaymentDetails();
  return (
    <div>
      <div className="billing-box cs-current-billing">
        <div className="billing-head">
          <h4 className="cs-regular-h4 cs-neutral-100">Current monthly bill</h4>
          <div className="subscription-plan-cards">
            <div className="card-plan-amount">
              <h2 className="cs-semi-bold-h1 cs-primary">
                ${firmPaymentDetails?.upcomingInvoice?.amount_due / 100 || 0}
              </h2>
            </div>
            <span className="cs-regular-h4 cs-neutral-70">+ applicable taxes</span>
          </div>
        </div>
        <div className="card-specification cs-light-body-text-l ">
          <ul>
            <li>
              <span>Monthly service fee</span>
              <div className="card-plan-amount">
                <h2 className="cs-semi-bold-h3 cs-neutral-100">
                  $ {firmPaymentDetails?.monthly_fee_price || 0}
                </h2>
              </div>
            </li>
            <li>
              <span>{`Professional licenses x ${firmPaymentDetails?.total_card_team_user || 0}`}</span>
              <div className="card-plan-amount">
                <h2 className="cs-semi-bold-h3 cs-neutral-100">
                  ${' '}
                  {firmPaymentDetails?.monthly_team_member_fee_price *
                    firmPaymentDetails?.total_card_team_user || 0}
                </h2>
              </div>
            </li>
            <li>
              <span>{`Client licenses x ${firmPaymentDetails?.total_card_client || 0}`}</span>
              <div className="card-plan-amount">
                <h2 className="cs-semi-bold-h3 cs-neutral-100">
                  ${' '}
                  {firmPaymentDetails?.monthly_client_fee_price *
                    firmPaymentDetails?.total_card_client || 0}
                </h2>
              </div>
            </li>
            <li>
              <span>{`${firmPaymentDetails?.is_file_management_plan_upgraded ? '100' : '50'}GB  File storage`}</span>
              <div className="card-plan-amount">
                <h2 className="cs-semi-bold-h3 cs-neutral-100">
                  ${' '}
                  {!firmPaymentDetails?.is_file_management_plan_upgraded
                    ? firmPaymentDetails?.file_storage_upgrade_price
                    : 0}
                </h2>
              </div>
            </li>
          </ul>
        </div>
        <Button
          className="cs-btn-primary lg-btn cs-regular-h5"
          onClick={() => setShowUpgradeModal(true)}>
          Upgrade
        </Button>
      </div>
    </div>
  );
};
CurrentMonthlyBill.propTypes = {
  setShowUpgradeModal: PropTypes.any
};
export default CurrentMonthlyBill;
