import React, { useEffect } from 'react';
import { Col, Row, Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';

import InputBox from '../../../components/FormFields/InputBox';
import { emailField } from '../../../validations';
import { resendEmailVerification, resetForm } from '../../../slices/authSlice';
import AuthHead from '../../AuthHead';
import { AUTH_CONTENT } from '../../../constants/content';
import { toast } from 'react-toastify';

const TokenExpired = (props) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: { email: '' },
    validationSchema: yup.object().shape({
      email: emailField()
    }),
    onSubmit: (values) => {
      dispatch(resendEmailVerification({ email: values.email }))
        .unwrap()
        .then((response) => {
          if (response.status) {
            navigation('/email-verification');
            toast.success(response?.message);
          } else {
            toast.error(response?.message);
          }
        })
        .catch((error) => console.log('error', error.message));
    }
  });

  useEffect(() => {
    dispatch(resetForm());
  }, []);

  return (
    <React.Fragment>
      <AuthHead
        title={AUTH_CONTENT.TOKEN_EXPIRED_TITLE}
        description={AUTH_CONTENT.TOKEN_EXPIRED_DETAIL}
        dynamicLogo={false}
      />
      <div className="cs-auth-form">
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg={12}>
              <InputBox
                type="email"
                name="email"
                values={values}
                errors={errors}
                touched={touched}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="auth-footer">
                <Button type="submit" className="cs-btn-primary lg-btn cs-regular-h5">
                  {' '}
                  Resend Email{' '}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default TokenExpired;
