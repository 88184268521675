import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// INITIAL STATE
import axios from '../config/api';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';




// GET PLANS
export const getVerificationPaymentList = createAsyncThunk(
    'billingHistory/getVerificationPaymentList',
    async () => {
        return await axios.get(`accreditation/payment_plans`);
    }
);
export const createIntentForCredit = createAsyncThunk(
    'billingHistory/createIntentForCredit',
    async (data) => {
        return await axios.post(`payment/create_intent_for_credit`, data);
    }
);
export const updateFirmSubscriptionPaymentQty = createAsyncThunk(
    'billingHistory/updateFirmSubscriptionPaymentQty',
    async (data) => {
        return await axios.post(`firm_payment/update_firm_subscription_payment_qty`, data);
    }
);
export const firmTransactionHistory = createAsyncThunk(
    'billingHistory/firmTransactionHistory',
    async (data) => {
        return await axios.post(`payment/get_firm_transaction_history`, data);
    }
);
export const getFirmPaymentDetails = createAsyncThunk(
    'billingHistory/getFirmPaymentDetails',
    async (data) => {
        return await axios.post(`payment/get_firm_payment_details`, data);
    }
);
export const upgradeDowngradeFileManagement = createAsyncThunk(
    'billingHistory/upgradeDowngradeFileManagement',
    async (data) => {
        return await axios.post(`firm_payment/upgrade_downgrade_file_management`, data);
    }
);
export const getFirmCreditList = createAsyncThunk(
    'billingHistory/getFirmCreditList',
    async (data) => {
        return await axios.post(`payment/get_firm_credit_list`, data);
    }
);
export const createSetupIntent = createAsyncThunk(
    'billingHistory/createSetupIntent',
    async (data) => {
        return await axios.post(`payment/create_setup_intent`, data);
    }
);
export const storeManualPaymentMethod = createAsyncThunk(
    'billingHistory/storeManualPaymentMethod',
    async (data) => {
        return await axios.post(`payment/store_manual_payment_method`, data);
    }
);
export const changeDefaultPaymentMethod = createAsyncThunk(
    'billingHistory/changeDefaultPaymentMethod',
    async (data) => {
        return await axios.post(`payment/change_default_payment_method`, data);
    }
);
export const removePaymentMethod = createAsyncThunk(
    'billingHistory/removePaymentMethod',
    async (data) => {
        const { id } = data;
        return await axios.post(`payment/remove_payment_method/${id}`);
    }
);


// INITIAL STATE
const initialState = {
    plansList: null,
    clientSecret: null,
    setupIntent: null,
    firmPaymentDetails: null,
    paymentHistory: null,
    firmCreditList: null,
    status: null,
    loading: false,
    message: ''
};

/* ============================== BILLING & HISTORY SLICE ============================== */
export const billingHistorySlice = createSlice({
    name: 'billingHistory',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getVerificationPaymentList.fulfilled, (state, action) => {
                const sortData = action?.payload?.data?.sort((a, b) => {
                    if (a.title && b.title) {
                        return a.title.localeCompare(b.title);
                    }
                    return 0;
                });
                state.plansList = sortData;
            })
            .addCase(createIntentForCredit.fulfilled, (state, action) => {
                state.clientSecret = action?.payload.data;
            })
            .addCase(firmTransactionHistory.fulfilled, (state, action) => {
                state.transactionHistory = action?.payload.data;
            })
            .addCase(getFirmPaymentDetails.fulfilled, (state, action) => {
                state.firmPaymentDetails = action?.payload.data;
                state.loading = false;
            })
            .addCase(getFirmPaymentDetails.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getFirmCreditList.fulfilled, (state, action) => {
                state.firmCreditList = action?.payload.data;
            })
            .addCase(createSetupIntent.fulfilled, (state, action) => {
                state.clientSecret = action?.payload.data;
            })
    }
});

const { reducer } = billingHistorySlice;
export default reducer;

export const selectPlans = (state) => state.billingHistory.plansList
export const usePlansList = () => {
    const plansList = useSelector(selectPlans)
    return useMemo(() => plansList, [plansList])
}

export const selectClientSecret = (state) => state.billingHistory.clientSecret
export const useClientSecret = () => {
    const clientSecret = useSelector(selectClientSecret)
    return useMemo(() => clientSecret, [clientSecret])
}

export const selectTransactionHistory = (state) => state.billingHistory.transactionHistory
export const useTransactionHistory = () => {
    const transactionHistory = useSelector(selectTransactionHistory)
    return useMemo(() => transactionHistory, [transactionHistory])
}
export const selectFirmCreditList = (state) => state.billingHistory.firmCreditList
export const useFirmCreditList = () => {
    const firmCreditList = useSelector(selectFirmCreditList)
    return useMemo(() => firmCreditList, [firmCreditList])
}
export const selectFirmPaymentDetails = (state) => state.billingHistory.firmPaymentDetails
export const useFirmPaymentDetails = () => {
    const firmPaymentDetails = useSelector(selectFirmPaymentDetails)
    return useMemo(() => firmPaymentDetails, [firmPaymentDetails])
}
export const selectCreateSetupIntent = (state) => state.billingHistory.setupIntent
export const useCreateSetupIntent = () => {
    const setupIntent = useSelector(selectCreateSetupIntent)
    return useMemo(() => setupIntent, [setupIntent])
}
export const selectLoadingState = (state) => state.billingHistory.loading
export const useLoadingState = () => {
    const loading = useSelector(selectLoadingState)
    return useMemo(() => loading, [loading])
}
