// Constants for options
const REGISTERED_INVESTORE_OPTIONS = ['Yes', 'No'];

const COMPANY_TYPE_OPTIONS = [
  'Limited Liability Company',
  'Private Company',
  'Public Company',
  'Not For Profit',
  'Partnership',
  'Other'
];

const BUSINESS_TYPE_OPTIONS = [
  'Limited Liability Company',
  'Private Company',
  'Public Company',
  'Not For Profit',
  'Partnership',
  'Other'
];

const TYPE_OF_CLIENT_OPTIONS = ['Individuals', 'Businesses', 'Both'];

const NUMBER_OF_CLIENTS_OPTIONS = [
  '0-500 clients',
  '501-2500 clients',
  '2501-10,000 clients',
  '10,001-50,000 clients',
  '50,001+ clients'
];

const INDUSTRY_OPTIONS = [
  'Banking',
  'Fintech',
  'Payments',
  'Lending',
  'Wealth Management',
  'Insurance',
  'Real Estate',
  'Accounting',
  'Cryptocurrency',
  'Other'
];

// Sidebar items
const SIDE_BAR_ITEMS = [
  {
    key: 1,
    title: 'Dashboard',
    path: '/dashboard',
    iconOutlined: 'dashboard-outlined',
    iconFilled: 'dashboard-filled',
    hasNotificationCount: false,
    keyName: 'dashboard'
  },
  {
    key: 2,
    title: 'Clients',
    path: '/dashboard/individual',
    iconOutlined: 'user-multiple-outlined',
    iconFilled: 'user-multiple-filled',
    hasNotificationCount: false,
    keyName: 'clients',
    subItems: [
      {
        key: 3,
        title: 'Individual',
        path: '/dashboard/individual',
        iconOutlined: 'user-single-outlined',
        iconFilled: 'user-single-filled',
        hasNotificationCount: false,
        keyName: 'individual'
      },
      {
        key: 4,
        title: 'Companies',
        path: '/dashboard/company',
        iconOutlined: 'company-outlined',
        iconFilled: 'company-filled',
        hasNotificationCount: false,
        keyName: 'company'
      }
    ]
  },
  {
    key: 5,
    title: 'request',
    path: '/dashboard/request',
    iconOutlined: 'message-request-outlined',
    iconFilled: 'message-request-filled',
    hasNotificationCount: false,
    keyName: 'request'
  }
];

// Types of Multi-factor authentication
const MFA_TYPES = {
  EMAIL: 'email',
  PHONE: 'phone',
  AUTHENTICATOR: 'google'
};

// Multi-factor authentication options data
const MFA_OPTIONS_DATA = [
  {
    type: MFA_TYPES.EMAIL,
    title: 'Email verification',
    description: 'Send a code to your email',
    icon: 'email-outlined',
    id: 'mobile',
    value: '2',
    redirectURL: '/verification-pin'
  },
  {
    type: MFA_TYPES.AUTHENTICATOR,
    title: 'Authenticator app',
    description: 'Connect your authentication app',
    icon: 'qr-code-outlined',
    id: 'authenticator',
    value: '3',
    redirectURL: '/verification-pin'
  }
];

// Multi-factor authentication type data
const MFA_TYPE_DATA = new Map([
  [
    MFA_TYPES.EMAIL,
    {
      title: 'Email verification',
      description: 'We have sent a code to',
      hasReceiverDetails: true
    }
  ],
  [
    MFA_TYPES.PHONE,
    {
      title: 'Phone verification',
      description: 'We have sent a code to',
      hasReceiverDetails: true
    }
  ],
  [
    MFA_TYPES.AUTHENTICATOR,
    {
      title: 'Authenticator app verification',
      description: 'Enter the 6 digits pin you seen in the app',
      hasReceiverDetails: false
    }
  ]
]);

// Multi-factor authentication options data for PFS password
const MFA_OPTIONS_PFS_PASSWORD = [
  {
    type: MFA_TYPES.EMAIL,
    title: 'Email verification',
    description: 'Send a code to your email',
    icon: 'email-outlined',
    id: 'email',
    value: '2',
    redirectURL: '/verification-pin'
  },
  {
    type: MFA_TYPES.PHONE,
    title: 'Phone verification',
    description: 'Send a code to your phone via SMS',
    icon: 'mobile-outlined',
    id: 'mobile',
    value: '2',
    redirectURL: '/verification-pin'
  },
  {
    type: MFA_TYPES.AUTHENTICATOR,
    title: 'Authenticator app',
    description: 'Connect your authentication app',
    icon: 'qr-code-outlined',
    id: 'authenticator',
    value: '3',
    redirectURL: '/verification-pin'
  }
];

// Paths for Active Tabs
const WL_PATHS = {
  DASHBOARD: 'dashboard',
  INDIVIDUAL: 'individual',
  COMPANY: 'company',
  REQUEST: 'request',
  SETTINGS: 'settings'
};

const DICTIONARY_WORDS = [
  'admin',
  'test',
  'ball',
  'bat',
  'cricket',
  'capsync',
  'samsung',
  'china',
  'trump',
  'college',
  'jump'
];

export {
  DICTIONARY_WORDS,
  REGISTERED_INVESTORE_OPTIONS,
  COMPANY_TYPE_OPTIONS,
  BUSINESS_TYPE_OPTIONS,
  TYPE_OF_CLIENT_OPTIONS,
  NUMBER_OF_CLIENTS_OPTIONS,
  INDUSTRY_OPTIONS,
  SIDE_BAR_ITEMS,
  MFA_OPTIONS_DATA,
  MFA_TYPE_DATA,
  MFA_OPTIONS_PFS_PASSWORD,
  MFA_TYPES,
  WL_PATHS
};
