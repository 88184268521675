import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Offcanvas, Badge, Button, Form } from 'react-bootstrap';

// COMPONENT IMPORTS
import Modal from '../../components/Modal';
import CapsyncIcon from '../../components/CapsyncIcon';
import { formatPhoneNumber } from '../../utils/common';
import CapsyncToolTip from '../../components/CapsyncToolTip';

// API
import { cancelSignature } from '../../slices/requestSlice';

export const getInitialName = (firstName, lastName) => {
  if (!firstName && !lastName) {
    return '';
  }
  const initials = (firstName.charAt(0) || '') + (lastName ? lastName.charAt(0) : '');
  return initials.toUpperCase();
};

/* ============================== OFF CANVAS ============================== */
const OffCanavas = ({ show, setShow, canvasDetails, accordionItemDetails, setOnSubmit }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const [isCancel, setIsCancel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [declineMessage, setDeclineMessage] = useState('');

  const isFirstUser =
    canvasDetails?.signature_users?.[0]?.is_first_user || canvasDetails?.is_first_user || false;

  const signStatus = canvasDetails?.signature_users?.[0]?.status || canvasDetails?.status || 0;

  // const signatureStatus = (status) => {
  //   switch (status) {
  //     case 1:
  //       return 'PENDING';
  //     case 2:
  //       return 'PENDING_WITH_SECOND';
  //     case 3:
  //       return 'COMPLETED';
  //     case 4:
  //       return 'DECLINED_BY_SECOND';
  //     case 5:
  //       return 'DECLINED';
  //     default:
  //       return 'Unknown';
  //   }
  // };

  const handleDeclineMessage = (e) => {
    setDeclineMessage(e.target.value);
  };

  const handleCancelSignature = () => {
    setLoading(true);
    dispatch(
      cancelSignature({
        signature_id: canvasDetails?.signature_users
          ? canvasDetails.signature_users[0].signature_id.toString()
          : canvasDetails.signature_id.toString(),
        cancel_by: user.email,
        reason: declineMessage ? declineMessage : '-'
      })
    ).then((res) => {
      if (res.payload.status) {
        setIsCancel(false);
        toast.success(res.payload.message);
        setShow(false);
        setOnSubmit(true);
      } else {
        toast.error(res.payload.message);
      }
      setLoading(false);
    });
  };

  return (
    <React.Fragment>
      <Offcanvas
        show={show}
        className="cs-off-canvas"
        placement="end"
        onHide={() => setShow(false)}>
        <Offcanvas.Header>
          <div className="img-container">
            <div className="off-canvas-avatar">
              <div className="cs-avatar cs-avatar-text">
                {canvasDetails?.signature_users
                  ? getInitialName(
                      canvasDetails?.signature_users[0].first_name,
                      canvasDetails?.signature_users[0].last_name
                    )
                  : getInitialName(canvasDetails.first_name, canvasDetails.last_name)}
              </div>
            </div>
            <span onClick={() => setShow(false)}>
              <CapsyncIcon title="close-outlined" size="18" />
            </span>
          </div>
          <div className="header-information">
            <div className="name-information">
              <span className="cs-semi-bold-h3">
                {canvasDetails?.signature_users
                  ? canvasDetails?.signature_users
                      .map((name) => name.first_name + ' ' + name.last_name)
                      .join(', ')
                  : canvasDetails.first_name + ' ' + canvasDetails.last_name}{' '}
              </span>
            </div>
            <div className="designation-information">
              <span className="cs-regular-sub-heading-m cs-neutral-60">{'Individual'}</span>
            </div>
          </div>
        </Offcanvas.Header>
        <div className="mob-scroller">
          <Offcanvas.Body>
            <div className="userInfo">
              <div className="userInfoSection">
                <div className="top-information-container">
                  <div className="body-top-information">
                    <div className="cs-medium-sub-heading-s">Email</div>
                    <p className="cs-regular-body-text-l cs-text-ellipsis">
                      {(() => {
                        const emails =
                          (canvasDetails?.signature_users ?? [])
                            .map((user) => user.email)
                            .join(', ') ||
                          canvasDetails?.email ||
                          '-';

                        return emails.length > 25 ? (
                          <CapsyncToolTip
                            trigger="click"
                            Child={emails}
                            placement="top"
                            message={emails}
                            type="text"
                          />
                        ) : (
                          emails
                        );
                      })()}
                    </p>
                  </div>
                  <div className="body-top-information">
                    <div className="cs-medium-sub-heading-s">Phone No</div>
                    <p className="cs-regular-body-text-l">
                      {canvasDetails?.signature_users
                        ? canvasDetails?.signature_users.map((name) =>
                            name && name.phone ? formatPhoneNumber(name.phone) : '-'
                          )
                        : canvasDetails && canvasDetails.phone
                          ? formatPhoneNumber(canvasDetails.phone)
                          : '-'}
                    </p>
                  </div>

                  {!canvasDetails?.signature_users && canvasDetails?.is_first_user && (
                    <div className="body-top-information">
                      <div className="cs-medium-sub-heading-s">Spouse Email</div>
                      <p className="cs-regular-body-text-l cs-text-ellipsis">
                        {accordionItemDetails?.signature_users?.length > 0
                          ? (() => {
                              const emails = accordionItemDetails?.signature_users
                                .filter((user) => !user.is_first_user)
                                .map((user) => user.email)
                                .join(', ');

                              if (emails?.length > 25) {
                                return (
                                  <CapsyncToolTip
                                    trigger="click"
                                    Child={emails}
                                    placement="top"
                                    message={emails}
                                    type="text"
                                  />
                                );
                              }
                              return emails;
                            })()
                          : '-'}
                      </p>
                    </div>
                  )}
                  {!canvasDetails?.signature_users && canvasDetails?.is_first_user && (
                    <div className="body-top-information">
                      <div className="cs-medium-sub-heading-s">Spouse Name</div>
                      <p className="cs-regular-body-text-l">
                        {accordionItemDetails?.signature_users
                          ?.filter((user) => !user.is_first_user)
                          ?.map((user) => `${user.first_name} ${user.last_name}`)
                          ?.join(', ')}
                      </p>
                    </div>
                  )}
                </div>
                <div className="top-information-container">
                  <div className="body-top-information">
                    <div className="cs-medium-sub-heading-s">Receiver's Name</div>
                    <p className="cs-regular-body-text-l">
                      {canvasDetails?.signature_users
                        ? canvasDetails?.receiver_first_name +
                          ' ' +
                          canvasDetails?.receiver_last_name
                        : accordionItemDetails?.receiver_first_name +
                          ' ' +
                          accordionItemDetails?.receiver_last_name}
                    </p>
                  </div>
                  <div className="body-top-information">
                    <div className="cs-medium-sub-heading-s">Receivers Email</div>
                    <p className="cs-regular-body-text-l cs-text-ellipsis">
                      {(() => {
                        const email =
                          canvasDetails?.signature_users?.length > 0
                            ? canvasDetails?.receiver_email
                            : accordionItemDetails?.receiver_email;

                        if (email && email.length > 25) {
                          return (
                            <CapsyncToolTip
                              trigger="click"
                              Child={email}
                              placement="top"
                              message={email}
                              type="text"
                            />
                          );
                        }

                        return email || '-';
                      })()}
                    </p>
                  </div>
                  <div className="body-top-information">
                    <div className="cs-medium-sub-heading-s">Notes</div>
                    <p className="cs-regular-body-text-l cs-line-clamp">
                      {canvasDetails?.signature_users
                        ? canvasDetails?.receiver_notes || '-'
                        : accordionItemDetails?.receiver_notes || '-'}
                    </p>
                  </div>
                </div>
                <div className="top-information-container">
                  <div className="body-top-information">
                    <div className="cs-medium-sub-heading-s">Request for</div>
                    <p className="cs-regular-body-text-l">Signature Request</p>
                  </div>
                  <div className="body-top-information">
                    <div className="cs-medium-sub-heading-s">Status</div>
                    <p className="cs-regular-body-text-l">
                      <Badge
                        className={`cs-badge cs-regular-body-text-s ${signStatus === 1 ? 'cs-warning' : signStatus === 2 ? 'cs-success' : 'cs-danger'}`}>
                        {signStatus === 1 ? 'Pending' : signStatus === 2 ? 'Completed' : 'Declined'}
                      </Badge>
                    </p>
                  </div>
                  <div className="body-top-information">
                    <div className="cs-medium-sub-heading-s">Request Date</div>
                    <p className="cs-regular-body-text-l">
                      {canvasDetails?.signature_users
                        ? moment(canvasDetails.createdAt).format('MM/DD/YYYY')
                        : moment(accordionItemDetails.createdAt).format('MM/DD/YYYY')}
                    </p>
                  </div>
                  <div className="body-top-information">
                    <div className="cs-medium-sub-heading-s">Complete</div>
                    <p className="cs-regular-body-text-l">
                      {canvasDetails?.signature_users &&
                      canvasDetails?.signature_date &&
                      signStatus !== 1
                        ? moment(canvasDetails.signature_date).format('MM/DD/YYYY')
                        : accordionItemDetails.signature_date && signStatus !== 1
                          ? moment(accordionItemDetails.signature_date).format('MM/DD/YYYY')
                          : '-'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Offcanvas.Body>
          {(canvasDetails?.signature_status === 1 && isFirstUser === true) ||
          (canvasDetails?.signature_status === 2 && isFirstUser === false) ? (
            <Button
              className="cs-btn-secondary xl-btn cs-regular-h5 canvas-cancel-btn"
              onClick={() => setIsCancel(true)}>
              Cancel
            </Button>
          ) : (accordionItemDetails?.signature_status === 1 && isFirstUser === true) ||
            (accordionItemDetails?.signature_status === 2 && isFirstUser === false) ? (
            <Button
              className="cs-btn-secondary xl-btn cs-regular-h5 canvas-cancel-btn"
              onClick={() => setIsCancel(true)}>
              Cancel
            </Button>
          ) : (
            ''
          )}
        </div>
      </Offcanvas>
      {isCancel && (
        <Modal
          show={isCancel}
          title="Cancel signature request"
          className="cs-md-modal"
          parentClassName="cs-common-modal-overlay"
          isCloseButton={false}
          body={
            <div className="decline-signature-request">
              <Form.Group className="cs-form-group last-form-field">
                <Form.Label className="cs-regular-sub-heading-xs cs-neutral-100">
                  Reason for cancelling (optional)
                </Form.Label>
                <textarea
                  autoComplete="off"
                  type="text"
                  className={declineMessage ? 'cs-input-field-active cs-textarea' : 'cs-textarea'}
                  onChange={(e) => handleDeclineMessage(e)}
                />
              </Form.Group>
            </div>
          }
          cancelButtonLabel="Cancel"
          saveButtonLabel="Submit"
          handleOnSave={handleCancelSignature}
          handleOnCancel={() => setIsCancel(false)}
          disabled={loading}
        />
      )}
    </React.Fragment>
  );
};

// PROPS TYPE
OffCanavas.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  canvasDetails: PropTypes.object,
  accordionItemDetails: PropTypes.object,
  setOnSubmit: PropTypes.func
};

export default OffCanavas;
