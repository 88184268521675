import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, FormLabel } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncIcon from '../CapsyncIcon';

/* ============================== INPUT BOX ============================== */
const InputBox = ({
  label,
  name,
  values,
  errors,
  touched,
  onChange,
  placeholder = '',
  type = 'text',
  disabled,
  max,
  status,
  onBlur,
  showPwdRequirements,
  submitCount,
  id = 'input-box',
  ...props
}) => {
  const [isPasswordShow, setIsPasswordShow] = useState(false);

  const onHandleTogglePasswordVisibility = () => {
    setIsPasswordShow(!isPasswordShow);
  };
  const handleKeyDown = (e) => {
    const isNumeric = type === 'number';

    if (e.key === ' ' && e.target.selectionStart === 0) {
      e.preventDefault();
    }
    if (
      e.key === 'Backspace' ||
      e.key === 'ArrowLeft' ||
      e.key === 'ArrowRight' ||
      e.key === 'Delete' ||
      e.key === 'Tab'
    ) {
      return;
    }

    if (isNumeric && max) {
      const currentValue = values[name] || '';
      const regex = new RegExp(`^\\d{1,${max}}$`);
      if (!regex.test(currentValue + e.key)) {
        e.preventDefault(); // Prevent adding more digits than max
      }
      if (!/^\d+$/.test(e.key)) {
        e.preventDefault(); // Allow only numeric keys
      }
    }
  };
  return (
    <Form.Group className={`cs-form-group ${disabled === true ? 'cs-disabled' : ''}`} id={id}>
      {label && <FormLabel>{label}</FormLabel>}
      <Form.Control
        className={values[name] ? 'cs-input-field-active' : ''}
        type={isPasswordShow ? 'text' : type}
        name={name}
        value={values[name]}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        autoComplete="off"
        disabled={disabled}
        onKeyDown={handleKeyDown}
        {...props}
      />
      {!showPwdRequirements && errors[name] && touched[name] ? (
        <span className="form-error-msg cs-light-body-text-s cs-danger">{errors[name]}</span>
      ) : submitCount && submitCount > 0 && !values[name] ? (
        <span className="form-error-msg cs-light-body-text-s cs-danger">{errors[name]}</span>
      ) : null}

      {status && status[name] && !errors[name] && (
        <span className="form-error-msg cs-light-body-text-s cs-danger"> {status[name]} </span>
      )}

      {type === 'password' && (
        <span
          className="input-field-icon cursor-pointer cs-neutral-80"
          onClick={onHandleTogglePasswordVisibility}
          onMouseDown={(e) => e.preventDefault()}>
          <CapsyncIcon
            title={isPasswordShow ? 'show-password-outlined' : 'hide-password-outlined'}
            size="18"
          />
        </span>
      )}
    </Form.Group>
  );
};

// PROPS TYPE
InputBox.propTypes = {
  name: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'password']),
  label: PropTypes.string,
  touched: PropTypes.object,
  disabled: PropTypes.bool,
  status: PropTypes.object,
  max: PropTypes.number,
  showPwdRequirements: PropTypes.bool,
  submitCount: PropTypes.number,
  id: PropTypes.string
};

export default InputBox;
