import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';

// COMPONENT IMPORTS
import axios from '../config/api';
import CONFIG from '../constants/config';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

// FIND ADDRESSES FROM GOOGLE API CALL
export const autoCompleteAddress = createAsyncThunk(
  'system/google/auto_search_address',
  async (data) => {
    const res = await axios.post(`${CONFIG.BASE_URL}google/auto_search_address`, data);
    return res;
  }
);

// GET SPECIFIC ADDRESS DATA FROM GOOGLE API CALL
export const placeSearch = createAsyncThunk('system/google/search_place', async (data) => {
  const res = await axios.post(`${CONFIG.BASE_URL}google/search_place`, data);
  return res;
});

// GET CITY AND STATE USING ZIP CODE FROM GOOGLE API CALL
export const zipCodeLookup = createAsyncThunk('system/google/search_by_zipcode', async (data) => {
  const res = await axios.post(`${CONFIG.BASE_URL}google/search_by_zipcode`, data);
  return res;
});

// SET SIDEBAR VISITED
export const setActiveNav = createAction('system/setActiveNav');

// TOGGLE SIDEBAR
export const toggleSidebar = createAction('system/toggleSidebar');

// INITIAL STATE
const initialState = {
  addressPredictions: {},
  addressPlaceSearch: {},
  addressZipCodeLookup: {},
  systemStatus: null,
  isSidebarOpen: true,
  activeNav: '/dashboard',
  isFmExpanded: false
};

/* ============================== SYSTEM SLICE ============================== */
export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setIsFmExpanded(state, action) {
      state.isFmExpanded = action.payload;
    }
  },
  extraReducers: (builder) => {
    // SYSTEM API STATUS
    builder
      .addCase(autoCompleteAddress.fulfilled, (state, { payload }) => {
        const { status: loader, data } = payload;
        state.systemStatus = loader ? CONFIG.STATUS.SUCCESS : CONFIG.STATUS.PROGRESS;
        state.addressPredictions = data;
      })
      .addCase(autoCompleteAddress.rejected, (state, { payload }) => {
        const { status } = payload;
        state.systemStatus = !status ? CONFIG.STATUS.ERROR : null;
      })
      .addCase(placeSearch.fulfilled, (state, { payload }) => {
        const { status: loader, data } = payload;
        state.systemStatus = loader ? CONFIG.STATUS.SUCCESS : CONFIG.STATUS.PROGRESS;
        state.addressPlaceSearch = data;
      })
      .addCase(placeSearch.rejected, (state, { payload }) => {
        const { status } = payload;
        state.systemStatus = !status ? CONFIG.STATUS.ERROR : null;
      })
      .addCase(zipCodeLookup.fulfilled, (state, { payload }) => {
        const { status: loader, data } = payload;
        state.systemStatus = loader ? CONFIG.STATUS.SUCCESS : CONFIG.STATUS.PROGRESS;
        state.addressZipCodeLookup = data;
      })
      .addCase(zipCodeLookup.rejected, (state, { payload }) => {
        const { status } = payload;
        state.systemStatus = !status ? CONFIG.STATUS.ERROR : null;
      })
      .addCase(toggleSidebar, (state, { payload }) => {
        state.isSidebarOpen = payload !== undefined ? payload : !state.isSidebarOpen;
      })
      .addCase(setActiveNav, (state, { payload }) => {
        state.activeNav = payload;
      });
  }
});

const { reducer } = systemSlice;
export const { setIsFmExpanded } = systemSlice.actions;
export default reducer;


const selectIsFmExpanded = (state) => state.system.isFmExpanded
export const useIsFmExpanded = () => {
  const isFmExpanded = useSelector(selectIsFmExpanded)
  return useMemo(() => isFmExpanded, [isFmExpanded])
}
