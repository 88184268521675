import React from 'react';
import { Col, Container, Row, Image, Button } from 'react-bootstrap';

// COMPONENT IMPORTS
import AuthBanner from './AuthBanner';
import Logo from '../assets/images/brand/capsync-logo.svg';

// HOOKS IMPORTS
import useRemoveServerError from '../hooks/useRemoveServerError';

/* ============================== THANK YOU ============================== */
const ThankYou = (props) => {
  useRemoveServerError();
  return (
    <section className="auth-section auth-common-section">
      <Container fluid>
        <Row className="align-items-center">
          <AuthBanner />
          <Col lg={6}>
            <div className="cs-right-section">
              <div className="cs-auth-header">
                <Image className="cs-logo" width="132" src={Logo} alt="Capsync Logo" />
                <div className="cs-title">
                  <h1 className="cs-semi-bold-h1">Thank you</h1>
                </div>
                <p className="cs-regular-body-text-m cs-neutral-90 text-center">
                  Thank you for submitting your information. A member of our team will reach out to
                  you soon.
                </p>
              </div>
              <div className="cs-auth-form">
                <Row>
                  <Col>
                    <div className="auth-footer">
                      <Button
                        type="submit"
                        className="cs-btn-primary lg-btn cs-regular-h5"
                        onClick={() => window.location.replace('https://capsync.com/')}>
                        Back to home
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ThankYou;
