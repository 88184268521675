import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CapsyncIcon from '../../../components/CapsyncIcon';
import { DICTIONARY_WORDS } from '../../../constants/system';
import { checkConsecutiveLetters, checkDictionary } from '../../../utils/common';
import CapsyncToolTip from '../../../components/CapsyncToolTip';

/* ============================== PASSWORD REQUIREMENT ============================== */
const PasswordRequirement = ({
  password,
  setShowPwdRequirements,
  inputFieldId = 'input-password-field'
}) => {
  const requirements = [
    { text: 'No spaces allowed', check: /^\S*$/ },
    { text: 'Min 8 characters', check: /^.{8,}$/ },
    { text: 'Max 20 characters', check: /^.{1,20}$/ },
    { text: 'Use lower case letters', check: /[a-z]/ },
    { text: 'Use upper case letters', check: /[A-Z]/ },
    { text: 'Use a number', check: /\d/ },
    { text: 'Use a symbol', check: /[~`!@#$%^&*(),.?":{}|<React.Fragment>+=_-]/ },
    {
      text: 'Choose a more secure password',
      check: DICTIONARY_WORDS,
      tooltip: 'Passwords cannot contain three consecutive identical or sequential characters.'
    }
  ];

  const handleClickOutside = (event) => {
    const container = document.getElementById('password-suggestion-container');
    const passwordInput = document.getElementById(inputFieldId);
    if (
      container &&
      !container.contains(event.target) &&
      passwordInput &&
      !passwordInput.contains(event.target)
    ) {
      setShowPwdRequirements(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const isValid = (requirement) => {
    if (Array.isArray(requirement.check)) {
      if (password.length < 8) {
        return false;
      }

      if (checkConsecutiveLetters(password.toLowerCase())) {
        return false;
      }

      if (checkDictionary(password)) {
        return false;
      }
      return true;
    } else {
      if (requirement.check.test(password)) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <ul className="password-requirements" id="password-suggestion-container">
      {requirements.map((requirement, index) => (
        <li
          key={index}
          className={`cs-light-body-text-s ${isValid(requirement) ? 'cs-success' : 'cs-danger'}`}>
          <span className={isValid(requirement) ? 'cs-success' : 'cs-danger'}>
            <CapsyncIcon
              title={isValid(requirement) ? 'check-outlined' : 'close-outlined'}
              size="14"
            />
          </span>
          {requirement.text}
          {requirement.tooltip && (
            <CapsyncToolTip
              Child={'info-outlined'}
              placement={'top'}
              message={requirement.tooltip}
              type="icon"
              size="22"
            />
          )}
        </li>
      ))}
    </ul>
  );
};

// PROPS TYPE
PasswordRequirement.propTypes = {
  password: PropTypes.string.isRequired,
  setShowPwdRequirements: PropTypes.func.isRequired,
  inputFieldId: PropTypes.string.isRequired
};

export default PasswordRequirement;
