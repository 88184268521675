import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

// COMPONENT IMPORTS
import Modal from '../../../components/Modal';
import config from '../../../constants/config';
import { TOAST_MESSAGE } from '../../../constants/message';
import { AddAccountValidationSchema } from '../validation';
import InputBox from '../../../components/FormFields/InputBox';
import SelectBox from '../../../components/FormFields/SelectBox';

// API
import { addCollabUser, allPermissionList, resetError } from '../../../slices/collaborationSlice';

/* ============================== ADD INDIVIDUAL MODAL ============================== */
const AddIndividualModal = ({ handleCloseModel, getAllCollaborationUsersList }) => {
  const dispatch = useDispatch();

  const { permissionOptionList, status, message } = useSelector((state) => state.collaboration);
  const { user } = useSelector((state) => state.auth);

  const [formError, setFormError] = useState('');
  const [loading, setLoading] = useState(false);

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, dirty } = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      permission: ''
    },
    validationSchema: AddAccountValidationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const reqData = {
        ...values,
        permission: values?.permission?.value.toString(),
        login_user_role: user.role_id.toString()
      };
      dispatch(addCollabUser(reqData)).then((res) => {
        if (res?.error?.message === 'Rejected') {
          setFormError(res?.payload);
          setLoading(false);
        } else {
          toast.success(TOAST_MESSAGE.INVITATION_SENT_SUCCESSFULLY);
          getAllCollaborationUsersList({
            page: 1,
            limit: 10
          });
          handleCloseModel();
          setLoading(false);
        }
      });
    }
  });

  useEffect(() => {
    if (status === config.STATUS.SUCCESS) {
      setFormError('');
    }
    status === config.STATUS.ERROR && setFormError(message);
  }, [status]);

  useEffect(() => {
    if (permissionOptionList?.length === 0) {
      dispatch(allPermissionList());
    }
  }, []);

  useEffect(() => {
    if (formError !== '') {
      setFormError('');
      dispatch(resetError());
    }
  }, [values]);

  return (
    <Modal
      title="Add New User"
      show={true}
      className="cs-md-modal cs-new-user-modal"
      body={
        <Form className="cs-complete-profile">
          <Row>
            <Col lg={6}>
              <InputBox
                label="First name"
                name="first_name"
                placeholder="Enter first name"
                values={values}
                errors={errors}
                touched={touched}
                onChange={handleChange}
              />
            </Col>
            <Col lg={6}>
              <InputBox
                label="Last name"
                name="last_name"
                placeholder="Enter last name"
                values={values}
                errors={errors}
                touched={touched}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <InputBox
                label="Email"
                name="email"
                type="email"
                placeholder="Email address"
                values={values}
                errors={errors}
                touched={touched}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <SelectBox
                className="cs-form-custom-select"
                value={values.permission}
                errors={errors}
                touched={touched}
                handleChangeSelect={(option) => setFieldValue('permission', option)}
                options={permissionOptionList}
                placeholder="Select permission"
                name="permission"
                label="Select permission"
              />
            </Col>
          </Row>
          <Row>
            <Col className="text-start">
              <span className="cs-light-body-text-m cs-danger">{formError}</span>
            </Col>
          </Row>
        </Form>
      }
      saveButtonLabel="Send Invitation"
      cancelButtonLabel="Cancel"
      handleOnSave={handleSubmit}
      handleOnCancel={handleCloseModel}
      isCloseButton={false}
      disabled={!dirty || loading}
    />
  );
};

// PROPS TYPE
AddIndividualModal.propTypes = {
  handleCloseModel: PropTypes.func,
  getAllCollaborationUsersList: PropTypes.func
};

export default AddIndividualModal;
