/* eslint camelcase: 0 */
import * as yup from 'yup';
import {
  cityField,
  dateField,
  streetNameField,
  textAreaField,
  websiteField,
  zipcodeField
} from '../../../validations';
import { VALIDATION_MESSAGE } from '../../../constants/message';

const stepOneValidationSchema = yup.object().shape({
  firm_name: yup
    .string()
    .required(VALIDATION_MESSAGE.COMPANY_NAME_REQUIRED)
    .matches(/^\S.*$/, VALIDATION_MESSAGE.WHITESPACE)
    .test('whitesspace', VALIDATION_MESSAGE.WHITESPACE, (value) => !/^\s+|\s+$/g.test(value))
    .max(40, VALIDATION_MESSAGE.REQUIRED_FORTY_CHARACTERS),
  business_type: yup.object().required(VALIDATION_MESSAGE.BUSINESS_TYPE_REQUIRED),
  ein_number: yup
    .string()
    .required(VALIDATION_MESSAGE.EIN_NUMBER_REQUIRED)
    .matches(/^\S.*$/, VALIDATION_MESSAGE.WHITESPACE)
    .matches(/^[0-9 -]+$/, VALIDATION_MESSAGE.ONLY_DIGITS)
    .test('whitesspace', VALIDATION_MESSAGE.WHITESPACE, (value) => !/^\s+|\s+$/g.test(value))
    .matches(/[0-9]{2}-[0-9]{7}/, VALIDATION_MESSAGE.EIN_NUMBER_FORMAT),
  incorporation_date: dateField()
});

const stepTwoValidationSchema = yup.object().shape({
  number_of_clients: yup.object().required(VALIDATION_MESSAGE.NUMBER_OF_CLIENTS_REQUIRED)
});

const stepThreeValidationSchema = yup.object().shape({
  street_name: streetNameField(),
  city: cityField(),
  zip_code: zipcodeField(),
  description: textAreaField('description'),
  website: websiteField()
});

const stepFourValidationSchema = yup.object().shape({
  industry: yup.object().required(VALIDATION_MESSAGE.INDUSTRY_REQUIRED)
});

export {
  stepOneValidationSchema,
  stepTwoValidationSchema,
  stepThreeValidationSchema,
  stepFourValidationSchema
};
