import React from 'react';
import { Col, Container, Row, Image, Button } from 'react-bootstrap';

// COMPONENT IMPORTS
import AuthBanner from './AuthBanner';
import Logo from '../assets/images/brand/capsync-logo.svg';

// HOOKS IMPORTS
import useRemoveServerError from '../hooks/useRemoveServerError';
import { useNavigate } from 'react-router-dom';

/* ============================== THANK YOU ============================== */
const ThankYouAfterSignUp = (props) => {
    useRemoveServerError();
    const navigate = useNavigate()
    return (
        <section className="auth-section auth-common-section">
            <Container fluid>
                <Row className="align-items-center">
                    <AuthBanner />
                    <Col lg={6}>
                        <div className="cs-right-section">
                            <div className="cs-auth-header">
                                <Image className="cs-logo" width="132" src={Logo} alt="Capsync Logo" />
                                <div className="cs-title">
                                    <h1 className="cs-semi-bold-h1">Thank you</h1>
                                </div>
                                <p className="cs-regular-body-text-m cs-neutral-90 text-center">
                                    Thank You! You’re all set. Get ready for an amazing experience!
                                </p>
                            </div>
                            <div className="cs-auth-form">
                                <Row>
                                    <Col>
                                        <div className="auth-footer">
                                            <Button
                                                type="submit"
                                                className="cs-btn-primary lg-btn cs-regular-h5"
                                                onClick={() => navigate("/")}>
                                                Sign in
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default ThankYouAfterSignUp;
