import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import SampleFile from '../../../assets/csv/SampleFile.csv';
import CapsyncIcon from '../../../components/CapsyncIcon';

/* ============================== BILK IMPORT CLIENT ============================== */
const BulkImportClient = ({
  setSelectedFile,
  selectedFile,
  setBulkImportError,
  bulkImportError,
  formError,
  setFormError
}) => {
  const [fileLoader, setFileLoader] = useState(false);

  const handleDrop = (acceptedFiles) => {
    setTimeout(() => {
      setFileLoader(true);
    }, 1500);

    if (acceptedFiles.length !== 1) {
      setBulkImportError('Only single CSV upload allowed.');
      setFormError('');
      return;
    }

    const file = acceptedFiles[0];

    const isValidFile = validateFile(file);

    if (isValidFile) {
      setSelectedFile(file);
      setBulkImportError(null);
      setFileLoader(false);
    } else {
      setSelectedFile(null);
    }
    setFormError('');
  };

  const validateFile = (file) => {
    const allowedTypes = ['text/csv'];
    if (!allowedTypes.includes(file.type)) {
      setBulkImportError('Only CSV format accepted.');
      return false;
    }
    setBulkImportError(null);
    return true;
  };

  const removeFile = () => {
    setSelectedFile(null);
    setFormError('');
    setBulkImportError(null);
  };

  return (
    <React.Fragment>
      <div className="financial-initial-bulk-upload">
        <h5 className="cs-regular-h5 cs-neutral-100">
          You can upload file with columns like <b className="cs-neutral-100">First name</b>,{' '}
          <b className="cs-neutral-100">Last name</b> and <b className="cs-neutral-100">Email</b> or
          download{' '}
          <Link to={SampleFile} download="Sample-CSV-File.csv" target="_blank" rel="noreferrer">
            sample file
          </Link>{' '}
          here
        </h5>
      </div>
      <div className="manage-profile-upload">
        <div className="cs-upload-box">
          <label className="cs-regular-sub-heading-s cs-neutral-100">Upload files</label>
          <Dropzone onDrop={handleDrop} accept="image/jpeg, image/png">
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className="cs-upload-container">
                <input {...getInputProps()} name="attachments" />
                <span className="cs-primary upload-icon">
                  <CapsyncIcon title="upload-files-filled" size="28" />
                </span>
                <p className="cs-regular-sub-heading-s cs-neutral-90">
                  <span>Browse</span>&nbsp;or drag file here
                </p>
                <p className="cs-regular-sub-heading-xs cs-neutral-70">Support CSV files</p>
              </div>
            )}
          </Dropzone>
          {bulkImportError && (
            <span className="cs-light-body-text-s cs-danger">{bulkImportError}</span>
          )}
          {formError && <span className="cs-light-body-text-s cs-danger">{formError}</span>}
        </div>
        {selectedFile && (
          <div className="cs-files">
            <div className="cs-scroller">
              <div className="cs-selected-files">
                <div className="cs-fileicon">
                  <span className="cs-neutral-60">
                    <CapsyncIcon title="file-icon-outlined" size="22" />
                  </span>
                </div>
                <div className="cs-files-names">
                  <span className="download-filename cs-regular-sub-heading-s cs-neutral-100 cs-text-ellipsis ">
                    {selectedFile && selectedFile.name}
                  </span>
                  <span className="download-filename cs-light-body-text-m cs-last-date cs-neutral-80">
                    {selectedFile && selectedFile.size / 1000} Kb
                  </span>
                </div>
                <div className="cs-file-icon icons">
                  {fileLoader === false ? (
                    <span className="cs-common-spinner cs-primary">
                      <CapsyncIcon title="loading-outlined" size="22" />
                    </span>
                  ) : (
                    <span
                      className="cs-icon-badge-danger cs-danger cursor-pointer"
                      onClick={() => removeFile()}>
                      <CapsyncIcon title="delete-outlined" size="18" />
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

// PROPS TYPE
BulkImportClient.propTypes = {
  setSelectedFile: PropTypes.func,
  selectedFile: PropTypes.object,
  setBulkImportError: PropTypes.func,
  setFormError: PropTypes.func,
  bulkImportError: PropTypes.string,
  formError: PropTypes.string
};

export default BulkImportClient;
