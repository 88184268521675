import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Image, Offcanvas } from 'react-bootstrap';

// COMPONENT IMPORTS
import CapsyncIcon from '../../../components/CapsyncIcon';
import { getInitialName } from '../../Request/OffCanavas';
import { formatPhoneNumber } from '../../../utils/common';

/* ============================== USER DETAILS TEAM COLLABORATION ============================== */
const UserDetailsTeamCollab = ({ handleCloseModel, data, show }) => {
  return (
    <Offcanvas className="cs-off-canvas" show={show} onHide={handleCloseModel} placement="end">
      <Offcanvas.Header>
        <div className="img-container">
          <div className="off-canvas-avatar">
            {data?.profile_image_url ? (
              <Image
                width="96"
                height="96"
                src={data.profile_image_url}
                className="cs-user-avtar cs-round"
                alt=""
              />
            ) : (
              <div className="cs-avatar cs-avatar-lg cs-avatar-md cs-avatar-text">
                {getInitialName(data?.first_name, data?.last_name)}
              </div>
            )}
          </div>
          <div className="cs-user-profile-toggle">
            <span
              className="close-outlined cs-menu-toggle cs-icon cs-neutral-100"
              onClick={handleCloseModel}>
              <CapsyncIcon title="close-outlined" size="22" />
            </span>
          </div>
        </div>
        <div className="header-information">
          <div className="cs-user-profile">
            <div className="name-information">
              <h3 className="cs-regular-h3 cs-neutral-100">{data?.display_name}</h3>
            </div>
            <div className="cs-regular-body-text-l cs-neutral-60">{data?.crd_number}</div>
          </div>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="top-information-container">
          <div className="body-top-information">
            <p className="cs-medium-sub-heading-s">Firm</p>
            <p className="cs-regular-body-text-l cs-neutral-100 off-canvas-request-email">
              {data?.firm_name || '-'} <span className="cs-neutral-60"> {data?.crd_number}</span>
            </p>
          </div>
          <div className="body-top-information">
            <p className="cs-medium-sub-heading-s cs-neutral-60">Email</p>
            <p className="cs-regular-body-text-l cs-neutral-100">{data?.email || '-'}</p>
          </div>

          <div className="body-top-information">
            <p className="cs-medium-sub-heading-s cs-neutral-60">Phone No</p>
            <p className="cs-regular-body-text-l cs-neutral-100">
              {data && data?.phone_no ? formatPhoneNumber(data?.phone_no) : '-'}
            </p>
          </div>
        </div>
        <div className="top-information-container">
          <div className="body-top-information">
            <p className="cs-medium-sub-heading-s cs-neutral-60">Role</p>
            <Badge className="cs-badge cs-regular-body-text-s cs-primary">{data?.role_name}</Badge>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

// PROPS TYPE
UserDetailsTeamCollab.propTypes = {
  handleCloseModel: PropTypes.func,
  data: PropTypes.object,
  show: PropTypes.bool
};

export default UserDetailsTeamCollab;
