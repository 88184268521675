// BackEnd App url for White label
const BASE_URL = process.env.REACT_APP_API_URL + '/api/';

// STATUS
const STATUS = {
  PROGRESS: 'progress',
  SUCCESS: 'success',
  ERROR: 'error'
};
export const AVATAR_LIST = [
  'https://wl-universal-local.s3.amazonaws.com/users/avatar/avatar_one.png',
  'https://wl-universal-local.s3.amazonaws.com/users/avatar/avatar_two.png',
  'https://wl-universal-local.s3.amazonaws.com/users/avatar/avatar_three.png',
  'https://wl-universal-local.s3.amazonaws.com/users/avatar/avatar_four.png',
  'https://wl-universal-local.s3.amazonaws.com/users/avatar/avatar_five.png'
];

export const STANDARD_USER_SETTINGS_PAGE_PATH = [
  '/dashboard/settings/custom-branding',
  '/dashboard/settings/team-collaboration',
  '/dashboard/settings/billing-history'
];
export const ADMIN_USER_SETTINGS_PAGE_PATH = [
  '/dashboard/settings/custom-branding',
  '/dashboard/settings/billing-history'
];

export const NAME_AVATAR = 'avatar_6.png';

export default {
  BASE_URL,
  STATUS
};
