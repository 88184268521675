import React, { useRef, useState, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CapsyncIcon from '../../components/CapsyncIcon';
import CommonSpinner from '../../components/CommonSpinner';
import CapsyncLogo from '../../assets/images/brand/Logo.png';
import UpdateAvatar from '../../components/userProfile/UpdateAvatar';
import CapsyncSmallLogo from '../../assets/images/brand/smallLogo.png';

/* ============================== LOGO IMAGE SETUP ============================== */
const LogoImageSetup = ({
  setLogoDetails,
  setActiveKey,
  setIsLoaderClicked,
  setSmallLogoFile,
  setPrimaryLogoFile,
  commonLoaderContinue,
  setCommonLoaderContinue,
  loaderCheckbox,
  setLoaderCheckbox,
  disableSmall,
  setDisableSmall,
  disablePrimary,
  setDisablePrimary
}) => {
  const { firm } = useSelector((state) => state.auth.user);

  const inputRef = useRef(null);

  const [primaryLogo, setPrimaryLogo] = useState(null);
  const [smallLogo, setSmallLogo] = useState(null);
  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [primaryLogoName, setPrimaryLogoName] = useState(null);
  const [smallLogoName, setSmallLogoName] = useState(null);
  const [logoImageType, setLogoImageType] = useState('');
  const [primaryLogoPreview, setPrimaryLogoPreview] = useState({
    link: CapsyncLogo,
    name: 'Logo.png'
  });
  const [smallLogoPreview, setSmallLogoPreview] = useState({
    link: CapsyncSmallLogo,
    name: 'smallLogo.png'
  });
  const [validationError, setValidationError] = useState('');
  const [logoType, setLogoType] = useState('');

  const handlePrimaryLogoChange = (event) => {
    setLogoType('primary');
    const file = event.target.files[0];
    if (file?.size > 5242880) {
      setPrimaryLogo(null);
      setValidationError('Allowing images up to 5 MB in size.');
      return;
    }
    const img = new Image();
    img.src = file && URL.createObjectURL(file);
    img.onload = () => {
      setPrimaryLogo(img.src);
      setSelectedAvatar(file);
      setPrimaryLogoName(file);
      setValidationError('');
    };
    img.onerror = () => {
      setPrimaryLogo(null);
      setValidationError('');
    };
  };

  const handleSmallLogoChange = (event) => {
    setLogoType('small');
    const file = event.target.files[0];
    if (file?.size > 5242880) {
      setSmallLogo(null);
      setValidationError('Allowing images up to 5 MB in size.');
      return;
    }
    const img = new Image();
    img.src = file && URL.createObjectURL(file);
    img.onload = () => {
      setSmallLogo(img.src);
      setSmallLogoName(file);
      setSelectedAvatar(file);
      setValidationError('');
    };
    img.onerror = () => {
      setSmallLogo(null);
      setValidationError('');
    };
  };

  const handleAvatarSelected = (title) => {
    setSelectedAvatar(title);
  };

  const validateUploadFiles = (newFile, setError, logo) => {
    const maxFileSize = 10 * 1024 * 1024; // 10MB in bytes
    let supportedExtensions;
    if (logo === 'primary') {
      supportedExtensions = ['.png', '.jpg', '.jpeg'];
    }
    if (logo === 'small') {
      supportedExtensions = ['.png', '.jpg', '.jpeg'];
    }
    // check file extension
    const fileExtension =
      newFile &&
      newFile.name &&
      newFile.name.slice(((newFile.name.lastIndexOf('.') - 1) >>> 0) + 2);
    const notAllowedExtensionHandling =
      fileExtension === '' || fileExtension === null || fileExtension === undefined;
    if (
      notAllowedExtensionHandling ||
      !supportedExtensions.includes(`.${fileExtension.toLowerCase()}`)
    ) {
      if (logo === 'primary') {
        setError('Only PNG, JPG, and JPEG files are supported.');
        setLogoType('primary');
      }
      if (logo === 'small') {
        setError('Only PNG, JPG, and JPEG files are supported.');
        setLogoType('small');
      }
      return false;
    }
    // Check for maximum file size
    if (newFile && newFile.size && newFile.size > maxFileSize) {
      if (logo === 'primary') {
        setError('This file exceeds maximum file size limit(10 MB).');
        setLogoType('primary');
      }
      if (logo === 'small') {
        setError('This file exceeds maximum file size limit(10 MB).');
        setLogoType('small');
      }
      return false;
    }
    setError('');
    return true;
  };

  const handleContinue = () => {
    setLogoDetails({
      primary_logo_url: primaryLogoPreview.link,
      primary_logo_name: primaryLogoPreview.name,
      small_logo_url: smallLogoPreview.link,
      small_logo_name: smallLogoPreview.name,
      is_use_load_image: loaderCheckbox
    });
    setActiveKey('2');
    setIsLoaderClicked(true);
  };

  useEffect(() => {
    if (firm && firm.firm_brand_details !== null) {
      setPrimaryLogoPreview({
        link: firm.firm_brand_details.primary_logo_s3_url,
        name: firm.firm_brand_details.primary_logo_url
      });
      setSmallLogoPreview({
        link: firm.firm_brand_details.small_logo_s3_url,
        name: firm.firm_brand_details.small_logo_url
      });
      setLoaderCheckbox(firm.firm_brand_details.is_use_load_image);
    }
  }, [firm]);

  return (
    <React.Fragment>
      <Form ref={inputRef}>
        <div className="upload-image-row">
          <Row>
            <Col lg={9}>
              <Form.Group className="cs-form-group">
                <Form.Label>Upload Primary Logo</Form.Label>
                <div className="cs-choose-file">
                  <div className="cs-choose-file-box">
                    <Form.Control type="file" accept="image/*" onChange={handlePrimaryLogoChange} />
                    <span className="cs-icon cs-neutral-10">
                      <CapsyncIcon title="upload-outlined" size="18" />
                    </span>
                  </div>
                  <Button
                    className="cs-btn-primary sm-btn cs-regular-h5"
                    disabled={!primaryLogo || (logoType === 'primary' && validationError !== '')}
                    onClick={() => {
                      setLogoImageType('primary');
                      setIsAvatarModalOpen(true);
                    }}>
                    Upload
                  </Button>
                </div>
                {logoType === 'primary' && validationError !== '' ? (
                  <p className="cs-light-body-text-s cs-danger">{validationError}</p>
                ) : (
                  <span className="cs-light-body-text-s cs-neutral-90">Image must be 1280X720</span>
                )}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <div className="cs-preview-image">
                <div className="cs-preview-title">
                  <h4 className="cs-regular-sub-heading-xs cs-neutral-100">Preview</h4>
                </div>
                <div className="cs-preview-box">
                  {primaryLogoPreview && <img src={primaryLogoPreview.link} alt="previewLogo" />}
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="upload-image-row">
          <Row>
            <Col lg={9}>
              <Form.Group className="cs-form-group">
                <Form.Label>Upload Small Logo</Form.Label>
                <div className="cs-choose-file">
                  <div className="cs-choose-file-box">
                    <Form.Control
                      type="file"
                      accept="image/*"
                      ref={inputRef}
                      onChange={handleSmallLogoChange}
                    />
                    <span className="cs-icon cs-neutral-10">
                      <CapsyncIcon title="upload-outlined" size="18" />
                    </span>
                  </div>
                  <Button
                    className="cs-btn-primary sm-btn cs-regular-h5"
                    disabled={!smallLogo || (logoType === 'small' && validationError !== '')}
                    onClick={() => {
                      setLogoImageType('small');
                      setIsAvatarModalOpen(true);
                    }}>
                    Upload
                  </Button>
                </div>
                {logoType === 'small' && validationError !== '' && (
                  <p className="cs-light-body-text-s cs-danger">{validationError}</p>
                )}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <div className="cs-preview-image">
                <div className="cs-preview-title">
                  <h4 className="cs-regular-sub-heading-xs cs-neutral-100">Preview</h4>
                </div>
                <div className="cs-preview-box cs-logo-box">
                  {smallLogoPreview && (
                    <img
                      className="fav-logo"
                      width={64}
                      height={64}
                      src={smallLogoPreview.link}
                      alt="previewLogo"
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="upload-image-row">
          <Row>
            <Col lg={9}>
              <Form.Group className="cs-form-group">
                <Form.Check
                  type={'checkbox'}
                  id={`default-checkbox`}
                  feedbackType="invalid"
                  className="cs-form-check-box"
                  aria-label="option 1"
                  name="terms"
                  onChange={() => {
                    setLoaderCheckbox(!loaderCheckbox);
                    setCommonLoaderContinue(true);
                  }}
                  checked={loaderCheckbox}
                  label={
                    <span className="cs-regular-body-text-m cs-neutral-100 cursor-pointer">
                      Use above as a loader Image
                    </span>
                  }
                />
                <span className="cs-light-body-text-s cs-neutral-90">
                  Loader icon may take up to 2 business days to implement.
                </span>
              </Form.Group>
            </Col>
            <Col lg={3}>
              {loaderCheckbox && smallLogoPreview ? (
                <div className="cs-preview-image">
                  <div className="cs-preview-title">
                    <h4 className="cs-regular-sub-heading-xs cs-neutral-100">Preview</h4>
                  </div>
                  <div className="cs-preview-box cs-logo-box">
                    <img
                      className="fav-logo cs-common-spinner"
                      width={64}
                      height={64}
                      src={smallLogoPreview.link}
                      alt="previewLogo"
                    />
                  </div>
                </div>
              ) : (
                <div className="cs-preview-image">
                  <div className="cs-preview-title">
                    <h4 className="cs-regular-sub-heading-xs cs-neutral-100">Preview</h4>
                  </div>
                  <div className="cs-preview-box cs-logo-box">
                    <CommonSpinner classParent={`cs-primary m-auto`} size={30} />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>

        <Row>
          <Col lg={12}>
            {firm.firm_brand_details == null ? (
              <Button
                className="cs-btn-primary sm-btn cs-regular-h5"
                disabled={
                  (disablePrimary && disableSmall) ||
                  primaryLogoPreview.link === CapsyncLogo ||
                  smallLogoPreview.link === CapsyncSmallLogo
                }
                onClick={handleContinue}>
                Continue
              </Button>
            ) : (
              <Button
                className="cs-btn-primary sm-btn cs-regular-h5"
                disabled={disablePrimary && disableSmall && !commonLoaderContinue}
                onClick={handleContinue}>
                Continue
              </Button>
            )}
          </Col>
        </Row>
      </Form>

      {isAvatarModalOpen && (
        <UpdateAvatar
          setIsAvatarModalOpen={setIsAvatarModalOpen}
          uploadType="customBranding"
          logoFile={primaryLogo}
          primaryLogoName={primaryLogoName}
          smallLogoName={smallLogoName}
          logoImageType={logoImageType}
          smallLogo={smallLogo}
          setValidationError={setValidationError}
          setSmallLogoPreview={setSmallLogoPreview}
          setSmallLogoFile={setSmallLogoFile}
          validateUploadFiles={validateUploadFiles}
          handleAvatarSelected={handleAvatarSelected}
          inputRef={inputRef}
          setLogoType={setLogoType}
          setSmallLogo={setSmallLogo}
          setDisableSmall={setDisableSmall}
          setPrimaryLogoPreview={setPrimaryLogoPreview}
          setPrimaryLogoFile={setPrimaryLogoFile}
          setPrimaryLogo={setPrimaryLogo}
          selectedAvatar={selectedAvatar}
          setSelectedAvatar={setSelectedAvatar}
          setDisablePrimary={setDisablePrimary}
        />
      )}
    </React.Fragment>
  );
};

// PROPS TYPE
LogoImageSetup.propTypes = {
  setLogoDetails: PropTypes.func,
  setActiveKey: PropTypes.func,
  setSmallLogoFile: PropTypes.func,
  setPrimaryLogoFile: PropTypes.func,
  setIsLoaderClicked: PropTypes.func,
  loaderCheckbox: PropTypes.bool,
  setLoaderCheckbox: PropTypes.func,
  disableSmall: PropTypes.bool,
  setDisableSmall: PropTypes.func,
  commonLoaderContinue: PropTypes.bool,
  setCommonLoaderContinue: PropTypes.func,
  disablePrimary: PropTypes.bool,
  setDisablePrimary: PropTypes.func
};

export default LogoImageSetup;
