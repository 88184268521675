import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import OtpInput from 'react-otp-input';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import Modal from '../Modal';
import { useFormik } from 'formik';
import CapsyncIcon from '../CapsyncIcon';
import { otpValidationSchema } from '../../validations';
import { MFA_TYPE_DATA, MFA_TYPES } from '../../constants/system';

// API
import { setMFA } from '../../slices/mfaSlice';
import { generateMFAOTP, verifyMFAOTP } from '../../slices/authSlice';

/* ============================== MFA VERIFICATION ============================== */
const MFAVerificationModal = (props) => {
  const {
    modalState,
    handleModalClose,
    mfaType,
    otherFileFlag = false,
    mfaFlag,
    handleSuccess,
    successToastMessage = ''
  } = props;

  const dispatch = useDispatch();
  const { user, mfa } = useSelector((state) => state.auth);

  const [modalData, setModalData] = useState({
    title: '',
    description: '',
    hasReceiverDetails: false
  });
  const [showResend, setShowResend] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const MfaData = mfaType && MFA_TYPE_DATA.get(mfaType);
    setModalData(MfaData);
    if (mfa.code === 200) {
      resetForm();
      otherFileFlag == false
        ? dispatch(setMFA({ ...mfa, pfsVerified: true }))
        : dispatch(setMFA({ ...mfa, otherPfsVerified: true }));
      handleModalClose();
      handleSuccess && handleSuccess();
    } else {
      const message = mfa.message;
      setFieldError('otp', message);
    }

    return () => setShowResend(false);
  }, [mfaType, mfa.message]);

  const forEmailOrPhone = mfaType === MFA_TYPES.EMAIL || mfaType === MFA_TYPES.PHONE;

  const initialValues = {
    otp: ''
  };

  const { handleSubmit, values, setFieldValue, errors, setFieldError, resetForm } = useFormik({
    initialValues: initialValues,
    validationSchema: otpValidationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const otp = values.otp;
      const payload = {
        user_id: user.id
      };

      dispatch(setMFA({ ...mfa, message: '', status: null, code: '' }));

      const verifyOTP = async (otpType, otpKey) => {
        payload[otpKey] = otp;
        const response = await dispatch(verifyMFAOTP({ mfaType: otpType, payload })).unwrap();
        const isSuccess = response?.code === 200;
        if (isSuccess && successToastMessage) toast.success(successToastMessage);
        setLoading(false);
        return isSuccess;
      };

      let otpVerified = false;
      if (!forEmailOrPhone) {
        otpVerified = await verifyOTP(MFA_TYPES.AUTHENTICATOR, 'google_otp');
      } else if (mfaType === MFA_TYPES.EMAIL) {
        otpVerified = await verifyOTP(MFA_TYPES.EMAIL, 'email_otp');
      } else if (mfaType === MFA_TYPES.PHONE) {
        otpVerified = await verifyOTP(MFA_TYPES.PHONE, 'phone_otp');
      }

      mfaFlag(otpVerified);
    }
  });

  useEffect(() => {
    dispatch(setMFA({ ...mfa, message: '', status: null, code: '' }));
    resetForm();
  }, [modalState]);

  const handleOTPChange = (value) => {
    setFieldValue('otp', value);
    setShowResend(false);
  };

  const handleResend = () => {
    dispatch(generateMFAOTP({ mfaType: mfaType, payload: { user_id: user && user.id } }));
    setShowResend(true);
    resetForm();
  };

  const bodyElem = () => {
    return (
      <div className="auth-right">
        <div className="formArea">
          <div className="text-center">
            {mfaType === MFA_TYPES.EMAIL && (
              <p className="cs-neutral-80 cs-regular-h5">
                {modalData?.description} <span className="cs-primary">{user && user.email}</span>
              </p>
            )}

            {mfaType === MFA_TYPES.PHONE && (
              <p className="cs-neutral-80 cs-regular-h5">
                {modalData?.description} <span className="cs-primary">{user && user.phone_no}</span>
              </p>
            )}

            {mfaType === MFA_TYPES.AUTHENTICATOR && (
              <p className="cs-neutral-80 cs-regular-h5">{modalData?.description}</p>
            )}

            <div className="cs-auth-form align-items-center cs-verification-pin">
              <div className="cs-otp">
                <Form>
                  <Row>
                    <Col lg={12}>
                      <OtpInput
                        value={values.otp}
                        onChange={handleOTPChange}
                        numInputs={6}
                        inputStyle="otp-txt"
                        containerStyle="otp-layout"
                        name="otp"
                        renderInput={(props) => <input {...props} />}
                        inputType="tel"
                      />
                      {!showResend && errors.otp && (
                        <span className="cs-light-body-text-m cs-danger">{errors.otp}</span>
                      )}

                      {forEmailOrPhone && (
                        <h5 className="cs-regular-h5">
                          <span className="auth-text-color-resend">
                            {' '}
                            Didn't receive the code?{' '}
                            <span className="cs-primary cursor-pointer" onClick={handleResend}>
                              Resend
                            </span>{' '}
                          </span>
                        </h5>
                      )}

                      {forEmailOrPhone && showResend && (
                        <div className="cs-msg default-regular-body-text-s cs-success">
                          <span className="icon">
                            <CapsyncIcon title="verification-outlined" size="18" />
                          </span>
                          <span className="cs-msg cs-regular-body-text-m cs-success">
                            {`We just sent you ${mfaType === MFA_TYPES.EMAIL ? 'an email' : 'a text'}. Enter the 6-digit code.`}
                          </span>
                        </div>
                      )}

                      {!forEmailOrPhone && (
                        <div className="cs-light-body-text-m cursor-pointer">
                          MFA code can be located in your Authenticator app installed on your
                          smartphone.
                        </div>
                      )}
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Modal
        title={modalData?.title}
        show={modalState}
        handleClose={handleModalClose}
        body={bodyElem()}
        isCloseButton={false}
        className="cs-md-modal"
        saveButtonLabel={'Verify account'}
        cancelButtonLabel={'Cancel'}
        handleOnSave={handleSubmit}
        handleOnCancel={handleModalClose}
        disabled={loading}
      />
    </div>
  );
};

// PROPS TYPE
MFAVerificationModal.propTypes = {
  modalState: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  mfaType: PropTypes.string.isRequired,
  mfaFlag: PropTypes.bool,
  otherFileFlag: PropTypes.bool,
  handleSuccess: PropTypes.func,
  successToastMessage: PropTypes.string
};

export default MFAVerificationModal;
