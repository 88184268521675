import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { Form, FormLabel } from 'react-bootstrap';

/* ============================== SELECT BOX ============================== */
const SelectBox = (props) => {
  const {
    name,
    options,
    value,
    placeholder,
    className,
    errors,
    touched,
    label,
    handleChangeSelect,
    disabled,
    status,
    parentClassName = '',
    isSearchable = true
  } = props;

  const IndicatorsContainer = (props) => {
    return name == 'state' ? (
      <div style={{ display: 'none' }}>
        <components.IndicatorsContainer {...props} />
      </div>
    ) : (
      <div>
        <components.IndicatorsContainer {...props} />
      </div>
    );
  };

  const colorStyles = {
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? '#eef1ff'
            : isFocused
              ? '#eef1ff'
              : undefined,
        color: isSelected ? '#0024D4' : '#424242'
      };
    }
  };
  return (
    <div className={`cs-dropdown select-dropdown ${parentClassName}`}>
      <Form.Group className={`cs-form-group ${disabled ? 'cs-disabled' : ''} cs-regular-body-text-m`}>
        {label && <FormLabel>{label}</FormLabel>}
        {options && (
          <Select
            value={value}
            onChange={handleChangeSelect}
            options={options}
            components={{ IndicatorsContainer }}
            placeholder={placeholder}
            name={name}
            className={
              'cs-regular-body-text-m ' +
              (disabled ? 'cs-disabled ' : '') +
              (className ? className : '')
            }
            styles={colorStyles}
            isDisabled={disabled}
            isSearchable={isSearchable}
          />
        )}
        {errors && errors[name] && touched[name] && (
          <span className="form-error-msg cs-light-body-text-s cs-danger">{errors[name]}</span>
        )}
        {status && status[name] && !errors[name] && (
          <span className="form-error-msg cs-light-body-text-s cs-danger">{status[name]}</span>
        )}
      </Form.Group>
    </div>
  );
};

// PROPS TYPE
SelectBox.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChangeSelect: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  status: PropTypes.object,
  parentClassName: PropTypes.string,
  isSearchable: PropTypes.bool
};

export default SelectBox;
