/* eslint new-cap: 0 */
/* eslint camelcase: 0 */
import React, { useEffect, useState } from 'react';
import { Row, Col, Form, FormLabel, Dropdown, FormControl, Image } from 'react-bootstrap';
import { GetState, GetCountries } from 'react-country-state-city';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import InputBox from './FormFields/InputBox';
import SelectBox from './FormFields/SelectBox';
import { getValidAddress } from '../utils/system';
import GoogleMapImg from '../assets/images/google-map.png';
import { VALIDATION_MESSAGE } from '../constants/message';

// API
import { autoCompleteAddress, placeSearch, zipCodeLookup } from '../slices/systemSlice';

/* ============================== ADDRESS SEARCH ============================== */
const AddressSearch = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    setAddressObj,
    record,
    setStatus,
    status,
    addressObj,
    setIsAddressChange = () => '',
    label = 'Address',
    setFieldTouched,
    licenceAddress,
    isCountryField,
    disabled,
    submitCount,
    step3
  } = props;
  const [stateOptionsList, setStateOptionsList] = useState([]);
  const [stateSelected, setStateSelected] = useState({ value: '', label: 'Choose State' });
  const [autocompletePredictions, setAutocompletePredictions] = useState([]);
  const [isCityNotFound, setIsCityNotFound] = useState(false);
  const [nearestLocalities, setNearestLocalities] = useState([]);

  const dispatch = useDispatch();
  const { addressPredictions, addressPlaceSearch, addressZipCodeLookup, systemStatus } =
    useSelector((state) => state.system);
  // Set US State List
  useEffect(() => {
    setIsAddressChange(false);
    GetCountries().then((result) => {
      const usCountryId = result.find((item) => item.iso2 === 'US');
      GetState(usCountryId.id).then((result) => {
        const stateList = result.map((item) => ({ value: item.name, label: item.name }));
        setStateOptionsList(stateList);
      });
    });
  }, []);

  // Set state value if addressObj already has any value
  useEffect(() => {
    addressObj &&
      addressObj.state != '' &&
      stateSelected.label === 'Choose State' &&
      setStateSelected({ value: addressObj.state, label: addressObj.state });
  }, [addressObj]);

  useEffect(() => {
    if (!!licenceAddress && licenceAddress.length > 0) {
      setAutocompletePredictions([...autocompletePredictions, ...licenceAddress]);
    }
  }, [licenceAddress]);

  // Set Record Data
  useEffect(() => {
    try {
      if (record) {
        const address = record;
        setFieldValue('street_name', address.line1);
        setFieldValue('city', address.city);
        setFieldValue('country', address.country);
        setFieldValue('zip_code', address.postal_code);
        setStateSelected({ label: address.state, value: address.state });
        setAddressObj(record);
      }
    } catch (error) {
      console.log(error);
    }
  }, [record]);

  // Handle Change State
  const handleChangeState = (option) => {
    setStateSelected(option);
    setIsAddressChange(true);
    if (
      !!values.zip_code &&
      values.zip_code.length === 5 &&
      !!addressObj.state &&
      option.value.toLowerCase() !== addressObj.state.toLowerCase()
    ) {
      setStatus({ ...status, state: VALIDATION_MESSAGE.STATE_NOT_MATCH });
    } else {
      setStatus({ ...status, state: '' });
    }
  };

  // Handle Change City
  const handleChangeCity = (e) => {
    handleChange(e);
    setIsAddressChange(true);
    if (!values.zip_code) {
      setStatus({ ...status, city: '' });
    }
    if (
      !!values.zip_code &&
      values.zip_code.length === 5 &&
      !!addressObj.city &&
      e.target.value.toLowerCase() !== addressObj.city.toLowerCase() &&
      !isCityNotFound
    ) {
      setStatus({ ...status, city: VALIDATION_MESSAGE.CITY_NOT_MATCH });
    } else {
      setStatus({ ...status, city: '' });
    }

    if (isCityNotFound) {
      setFieldValue('city', e.target.value);
      setAddressObj({ ...addressObj, city: e.target.value });
    }
  };
  const handleBlurCity = () => {
    if (isCityNotFound) {
      const checkValidCity =
        nearestLocalities &&
        nearestLocalities.length > 0 &&
        nearestLocalities.some((item) => item.toLowerCase() === values.city.toLowerCase());
      if (checkValidCity) {
        setStatus({ ...status, city: '' });
      } else {
        setStatus({ ...status, city: VALIDATION_MESSAGE.CITY_NOT_MATCH });
      }
    }
  };

  // ZipCode API and Handle Change
  const handleChangeZipCode = (e) => {
    const zipCode = e.target.value;
    setIsAddressChange(true);
    if (e.target.name === 'zip_code' && e.target.value !== '' && e.target.value.length === 5) {
      onHandleZipCode(zipCode);
    }
    handleChange(e);
    setStatus({});
  };
  const onHandleZipCode = async (inputValue) => {
    await dispatch(zipCodeLookup({ zip_code: inputValue }));
  };
  useEffect(() => {
    if (addressZipCodeLookup && addressZipCodeLookup.results) {
      const address =
        addressZipCodeLookup.results.length > 0
          ? addressZipCodeLookup.results[0].address_components
          : addressZipCodeLookup.results;
      if (address.length === 0) {
        setStatus({ ...status, zip_code: 'Invalid zip code!' });
        return;
      }

      let addressObject = getValidAddress(address);
      addressObject = addressObject.error ? addressObject.address : addressObject;
      const { city, country, state, state_code } = addressObject;

      if (country !== 'US') {
        setStatus({ ...status, zip_code: 'Out of USA zip code not allowed!' });
        return;
      }

      if (!city) {
        setIsCityNotFound(true);
        setNearestLocalities(
          addressZipCodeLookup.results.length > 0
            ? addressZipCodeLookup.results[0].postcode_localities
            : []
        );
      } else {
        setIsCityNotFound(false);
        setNearestLocalities([]);
      }
      // Update values for user details
      setFieldValue('city', city);
      setFieldValue('country', country);
      setFieldValue('zip_code', values.zip_code);
      setStateSelected({ label: state, value: state });

      setFieldTouched('city', false, false);
      setAddressObj({
        ...addressObj,
        postal_code: values.zip_code,
        city,
        country,
        state,
        state_code
      });
    }
  }, [addressZipCodeLookup]);
  // Google API and Handle Change
  const handleChangeAddress = async (event) => {
    const inputValue = event.target.value;
    setIsAddressChange(true);
    setFieldValue('street_name', inputValue);
    setAddressObj({ ...addressObj, line1: inputValue, line2: '' });
    setStatus({ ...status, street_name: '' });

    await dispatch(autoCompleteAddress({ search_data: inputValue }));
  };
  useEffect(() => {
    if (systemStatus === 'success' && addressPredictions) {
      addressPredictions.status === 'OK' &&
        !!addressPredictions.predictions &&
        setAutocompletePredictions(addressPredictions.predictions);
    }
  }, [addressPredictions]);

  const handleOptionSelect = async (value) => {
    const selectedAddress = autocompletePredictions.filter(
      (prediction) => prediction.description === value
    )[0];
    selectedAddress && (await dispatch(placeSearch({ add_place_id: selectedAddress.place_id })));
  };
  useEffect(() => {
    if (systemStatus === 'success' && addressPlaceSearch.status === 'OK') {
      let addressObj =
        !!addressPlaceSearch.result &&
        !!addressPlaceSearch.result.address_components &&
        getValidAddress(addressPlaceSearch.result.address_components);
      if (addressObj.error) {
        addressObj.error.includes('city') ? setIsCityNotFound(true) : setIsCityNotFound(false);
        const isStreetNumberError = addressObj.error.includes('street number') ? true : false;
        addressObj = addressObj.address;
        setAddressObj({
          ...addressObj,
          line1: isStreetNumberError ? addressObj.line1 : values.street_name
        });
        setFieldValue('street_name', isStreetNumberError ? addressObj.line1 : values.street_name);
        // Set predictions as empty due to error
        setAutocompletePredictions([]);
      } else {
        setFieldValue('street_name', addressObj.line1);
        setAddressObj(addressObj);
      }

      setFieldValue('city', addressObj.city);
      setFieldValue('zip_code', addressObj.postal_code);
      setStateSelected({ value: addressObj.state, label: addressObj.state });

      setFieldTouched('street_name', false, false);
      setFieldTouched('city', false, false);
      setFieldTouched('zip_code', false, false);

      setStatus({});
      setIsAddressChange(true);
    }
  }, [addressPlaceSearch]);

  useEffect(() => {
    if (step3 && step3?.address === '') {
      setStateSelected({ value: '', label: 'Choose State' });
    }
  }, [step3]);

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Form.Group className={`cs-form-group ${disabled ? 'cs-disabled' : ''}`}>
            <FormLabel>{label}</FormLabel>
            <Dropdown className="cs-form-dropdown cs-address-dropdown">
              <Dropdown.Toggle>
                <FormControl
                  type="text"
                  autoComplete="off"
                  name="street_name"
                  value={values.street_name}
                  className={values.street_name ? 'cs-input-field-active' : ''}
                  onChange={handleChangeAddress}
                  id="address-input"
                  disabled={disabled}
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="cs-scroller cs-setup-dropdown">
                {autocompletePredictions.map((prediction) => (
                  <Dropdown.Item
                    key={prediction.place_id}
                    onClick={() => handleOptionSelect(prediction.description)}
                    className="google-map">
                    <span>{prediction.description}</span>
                    <Image src={GoogleMapImg} alt="Google Map Image" width={30} height={30} />
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
              {errors.street_name && touched.street_name ? (
                <p className="form-error-msg cs-light-body-text-s cs-danger">
                  {errors.street_name}
                </p>
              ) : !values.street_name && submitCount && submitCount > 0 ? (
                <p className="form-error-msg cs-light-body-text-s cs-danger">
                  {errors.street_name}
                </p>
              ) : null}
              {status && status.street_name && !errors.street_name && (
                <span className="form-error-msg cs-light-body-text-s cs-danger">
                  {status.street_name}
                </span>
              )}
            </Dropdown>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={isCountryField ? 6 : 4}>
          <InputBox
            name="city"
            values={values}
            errors={errors}
            touched={touched}
            status={status}
            onChange={handleChangeCity}
            autoComplete="off"
            label="City"
            disabled={disabled}
            onBlur={handleBlurCity}
            submitCount={submitCount}
          />
        </Col>
        <Col lg={isCountryField ? 6 : 4}>
          <SelectBox
            value={stateSelected}
            errors={errors}
            touched={touched}
            handleChangeSelect={(e) => handleChangeState(e)}
            options={stateOptionsList}
            placeholder="Select state"
            name="state"
            label="State"
            disabled={disabled}
            status={status}
          />
        </Col>
        <Col lg={isCountryField ? 6 : 4}>
          <InputBox
            name="zip_code"
            values={values}
            errors={errors}
            touched={touched}
            status={status}
            onChange={handleChangeZipCode}
            autoComplete="off"
            label="Zip code"
            disabled={disabled}
            maxLength={5}
            submitCount={submitCount}
          />
        </Col>
        {isCountryField && (
          <Col lg={isCountryField ? 6 : 4}>
            <InputBox
              name="country"
              values={values}
              errors={errors}
              autoComplete="off"
              label="Country"
              disabled={true}
              submitCount={submitCount}
            />
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
};

// PROPS TYPE
AddressSearch.propTypes = {
  status: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
  addressObj: PropTypes.object,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  setAddressObj: PropTypes.func,
  record: PropTypes.object,
  setStatus: PropTypes.func,
  setIsAddressChange: PropTypes.func,
  setFieldTouched: PropTypes.func,
  label: PropTypes.string,
  licenceAddress: PropTypes.object,
  isCountryField: PropTypes.bool,
  disabled: PropTypes.bool,
  submitCount: PropTypes.number,
  step3: PropTypes.object
};

export default AddressSearch;
