import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CapsyncIcon from '../../../components/CapsyncIcon';

// API
import { setActiveNav, setIsFmExpanded, toggleSidebar } from '../../../slices/systemSlice';
import { getPendingSignatureList } from '../../../slices/requestSlice';

/* ============================== SIDEBAR ITEM ============================== */
const SideBarItem = ({ item }) => {
  const { key, title, path, iconOutlined = '', iconFilled, keyName } = item;
  const dispatch = useDispatch();

  const { activeNav } = useSelector((state) => state.system);
  const { isSidebarOpen } = useSelector((state) => state.system);
  const { requestRecord, requestPagination } = useSelector((state) => state.request);

  const handleNavItemClick = (keyName) => {
    dispatch(setIsFmExpanded(false));
    dispatch(setActiveNav(keyName));
    if (isSidebarOpen && window.innerWidth <= 991) {
      dispatch(toggleSidebar());
    }
  };

  useEffect(() => {
    dispatch(
      getPendingSignatureList({
        page: 1,
        listType: 'pending',
        isPaginate: true,
        limit: 10
      })
    );
  }, []);

  const pendingRequest = requestPagination?.itemCount
    ? requestPagination?.itemCount
    : requestRecord?.length;

  return (
    <li className="cs-nav-item nav-item" key={key} onClick={() => handleNavItemClick(item.keyName)}>
      <Link
        to={path}
        className={`cs-side-nav cs-regular-sub-heading-m cs-neutral-80 ${activeNav === keyName ? 'active' : ''}`}>
        <span className={`${iconOutlined} cs-icon`}>
          <CapsyncIcon title={`${activeNav === keyName ? iconFilled : iconOutlined}`} size="22" />
        </span>
        <span className="cs-nav-text">{title}</span>
        {pendingRequest > 0 && title === 'request' && (
          <span className="cs-notification-count">
            <span className="cs-nav-text">{pendingRequest}</span>
          </span>
        )}
      </Link>
    </li>
  );
};

// PROPS TYPE
SideBarItem.propTypes = {
  item: PropTypes.shape({
    key: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    iconOutlined: PropTypes.string.isRequired,
    iconFilled: PropTypes.string.isRequired,
    hasNotificationCount: PropTypes.bool,
    keyName: PropTypes.string.isRequired
  }).isRequired
};

export default SideBarItem;
