import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Image, Form, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import Cookies from 'universal-cookie';
import { useFormik } from 'formik';

// COMPONENT IMPORTS
import AuthBanner from './AuthBanner';
import { otpValidationSchema } from '../validations';
import { VALIDATION_MESSAGE } from '../constants/message';
import { deviceVerifyCookieStore } from '../utils/system';
import Logo from '../assets/images/brand/capsync-logo.svg';
import { MFA_TYPES, MFA_TYPE_DATA } from '../constants/system';

// HOOKS IMPORTS
import useAuthRedirect from '../hooks/useAuthRedirect';

// API
import { clearMFA, setMFA } from '../slices/mfaSlice';
import { generateMFAOTP, useUserID, verifyMFAOTP } from '../slices/authSlice';
import { useBrandDetails } from '../slices/brandDetailSlice';

/* ============================== VERIFICATION PIN ============================== */
const VerificationPin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const brandDetails = useBrandDetails();
  const userID = useUserID();
  const cookies = new Cookies();

  const { user, mfa } = useSelector((state) => state.auth);
  const [data, setData] = useState({ title: '', description: '', hasReceiverDetails: false });
  const forEmailOrPhone = mfa && (mfa.type === MFA_TYPES.EMAIL || mfa.type === MFA_TYPES.PHONE);

  const [deviceVerify, setDeviceVerify] = useState(false);
  const [showResend, setShowResend] = useState(false);

  useAuthRedirect();

  useEffect(() => {
    mfa.type && setData(MFA_TYPE_DATA.get(mfa.type));
    if (mfa.code === 200) {
      resetForm();
      navigate('/dashboard');
      dispatch(clearMFA());
      dispatch(setMFA({ verified: true }));
    } else {
      const message = mfa.message || VALIDATION_MESSAGE.INTERNAL_SERVER_ERROR;
      setFieldError('otp', message);
    }
  }, [mfa && mfa]);

  const initialOTPValue = {
    otp: ''
  };

  const handleOTPChange = (value) => {
    setFieldValue('otp', value);
    setShowResend(false);
  };

  const { handleSubmit, values, setFieldValue, errors, submitCount, setFieldError, resetForm } =
    useFormik({
      initialValues: initialOTPValue,
      validationSchema: otpValidationSchema,
      onSubmit: (values) => {
        const otp = values.otp;
        deviceVerifyCookieStore(userID, deviceVerify);
        const payload = {
          user_id: user.id
        };
        if (!forEmailOrPhone) {
          payload.google_otp = otp;
          dispatch(verifyMFAOTP({ mfaType: MFA_TYPES.AUTHENTICATOR, payload }));
        } else {
          payload.email_otp = otp;
          dispatch(verifyMFAOTP({ mfaType: MFA_TYPES.EMAIL, payload }));
        }
      }
    });

  const handleResend = () => {
    dispatch(generateMFAOTP({ mfaType: MFA_TYPES.EMAIL, payload: { user_id: user && user.id } }));
    setShowResend(true);
    resetForm();
  };
  const handleRemember = (e) => {
    if (e.target.checked) {
      setDeviceVerify(e.target.checked);
    } else {
      setDeviceVerify(false);
      cookies.remove(`deviceAuth24Hr-${userID}`);
    }
  };

  return (
    <section className="auth-section verification-section">
      <Container fluid>
        <Row className="align-items-center">
          <AuthBanner />
          <Col lg={6}>
            <div className="cs-right-section">
              <div className="cs-auth-header">
                <Image
                  className="cs-logo"
                  width="132"
                  src={
                    brandDetails && brandDetails?.is_domain_verified === true
                      ? brandDetails.primary_logo_s3_url
                      : Logo
                  }
                  alt="Capsync Logo"
                />
                <div className="cs-title">
                  <h1 className="cs-semi-bold-h1">{data.title}</h1>
                </div>
                <p className="cs-regular-body-text-m cs-neutral-90 text-center">
                  {data.description}{' '}
                  {data.hasReceiverDetails && (
                    <span className="cs-primary">{user && user.email}</span>
                  )}
                </p>
              </div>
              <div className="cs-auth-form">
                <div className="cs-otp">
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg={12}>
                        <OtpInput
                          value={values.otp}
                          onChange={handleOTPChange}
                          numInputs={6}
                          inputStyle="otp-txt"
                          containerStyle="otp-layout"
                          name="otp"
                          renderInput={(props) => <input {...props} />}
                        />
                        {submitCount > 0 && errors.otp && (
                          <span className="cs-light-body-text-m cs-danger">{errors.otp}</span>
                        )}

                        {forEmailOrPhone && (
                          <div className="cs-regular-h5">
                            <span className="auth-text-color-resend">
                              {' '}
                              Didn't receive the code? <Link onClick={handleResend}>
                                Resend
                              </Link>{' '}
                            </span>
                          </div>
                        )}
                      </Col>
                      <Col lg={12}>
                        <div className="auth-footer">
                          <Button
                            type="submit"
                            className="cs-btn-primary lg-btn cs-regular-h5 cs-ver-btn">
                            Verify account
                          </Button>
                          {showResend && forEmailOrPhone && (
                            <span className="cs-msg cs-regular-body-text-s cs-success">
                              We just sent you an email. Enter the 6-digit code.
                            </span>
                          )}

                          <div>
                            <Form.Check
                              type={'checkbox'}
                              id={`default-checkbox`}
                              feedbackType="invalid"
                              className="cs-form-check-box"
                              checked={deviceVerify}
                              onChange={handleRemember}
                              aria-label="option 1"
                              name="terms"
                              label={
                                <span className="cs-light-body-text-m cursor-pointer d-flex">
                                  Trust this device for 180 days
                                </span>
                              }
                            />
                          </div>

                          {!forEmailOrPhone && (
                            <span className="cs-light-body-text-m">
                              MFA code can be located in your Authenticator app installed on your
                              smartphone.
                            </span>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default VerificationPin;
