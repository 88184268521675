import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Image } from 'react-bootstrap';
import PropTypes from 'prop-types';

// COMPONENT IMPORTS
import CapsyncIcon from '../CapsyncIcon';
import CommonSpinner from '../CommonSpinner';

/* ============================== AVATAR ============================== */
const Avatar = ({ isEditable = false, className = '', url = '', setIsAvatarModalOpen, src }) => {
  const { user } = useSelector((state) => state.auth);
  const trimmedSrc =
    src &&
    src.replace(
      /(https:\/\/wl-universal-(local|dev|stage)\.s3\.amazonaws\.com|https:\/\/s3\.amazonaws\.com\/universal-clientportalx\.com)/,
      ''
    );

  const [initials, setInitials] = useState('');

  const handleAvatarModal = () => {
    setIsAvatarModalOpen(true);
  };
  const [isLoading, setIsLoading] = useState(
    src !== null &&
      src !== 'null' &&
      user?.user_personal_details !== null &&
      trimmedSrc !== '/users/avatar/avatar_6.png'
      ? true
      : false
  );

  const stopLoading = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    const name = user.display_name;
    if (name) {
      const nameArray = name.split(' ');
      const firstName = nameArray[0];
      const lastName = nameArray[nameArray.length - 1];
      const initials = firstName.charAt(0) + lastName.charAt(0);
      setInitials(initials.toUpperCase());
    }
  }, [user]);

  return (
    <div className="cs-profile">
      <div className={`cs-avatar cs-avatar-lg ${className}`}>
        {isLoading && <CommonSpinner />}
        {src !== null && src !== 'null' && trimmedSrc !== '/users/avatar/avatar_6.png' ? (
          <Image src={src} onLoad={stopLoading}
            onError={stopLoading} style={{ display: isLoading ? 'none' : 'block' }} />
        ) : (
          <span className="cs-user">{initials}</span>
        )}
        {isEditable && (
          <span className="cs-edit cs-neutral-10" onClick={handleAvatarModal}>
            <CapsyncIcon title="edit-image-filled" size="18" />
          </span>
        )}
      </div>
    </div>
  );
};

// PROPS TYPE
Avatar.propTypes = {
  isEditable: PropTypes.bool,
  className: PropTypes.string,
  url: PropTypes.string,
  setIsAvatarModalOpen: PropTypes.func,
  src: PropTypes.string
};

export default Avatar;
