import React from 'react';
import Modal from '../../../../components/Modal';
import PropTypes from 'prop-types';
import CapsyncIcon from '../../../../components/CapsyncIcon';

const UpgradeModal = ({ showUpgradeModal, setShowUpgradeModal }) => {
  const modalBody = () => {
    return (
      <div className="cs-storageplan">
        <span className="cs-neutral-70">
          <CapsyncIcon size="100" title="user-multiple-filled" />
        </span>
        <p className="cs-regular-body-text-m neutral-90">
          Please contact your account administrator to upgrade your plan.
        </p>
      </div>
    );
  };

  const handleSubmit = async () => {
    setShowUpgradeModal(false);
  };

  const closeModal = () => setShowUpgradeModal(false);

  return (
    <div>
      <Modal
        className="stripe-form cs-md-modal upgrade-plan"
        show={showUpgradeModal}
        title="Upgrade Plan"
        body={modalBody()}
        saveButtonLabel="Okay"
        handleOnSave={handleSubmit}
        buttonCenter={true}
        handleOnCancel={closeModal}
        isCloseButton={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="cs-md-modal"
        modalFooterClass="cs-center-btn"
      />
    </div>
  );
};

UpgradeModal.propTypes = {
  showUpgradeModal: PropTypes.func,
  setShowUpgradeModal: PropTypes.bool
};

export default UpgradeModal;
